<template>
  <div class="cp-CompanyPage">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="$t('settings.company.header')"
      no-margin
    />
    <div class="cp-MainContent">
      <UseAppPlatform v-slot="{ isWebApp, isCrossPlatformApp }">
        <div v-if="(isWebApp || isCrossPlatformApp) && isPluginAdmin" class="cp-OptionWrapper">
          <OrganizationNameSettings />
          <AppDivider class="cp-Divider" no-margin />
        </div>
      </UseAppPlatform>
      <div class="cp-OptionWrapper">
        <AppTitle :level="4" class="cp-OptionTitle" disable-margin>
          {{ $t('settings.company.alignment_settings') }}
        </AppTitle>
        <AppCheckbox
          v-model="linkObjToKR"
          data-auto-testid="link-obj-to-kr-switch"
          data-testid="link-obj-to-kr-switch"
          @update:model-value="
            saveSettingsParameter({
              parameter: COMPANY_SETTINGS_ENTITY_KEYS.LINK_OBJ_TO_KR,
              value: $event
            })
          "
        >
          <i18n-t keypath="settings.company.alignment_settings_switch" scope="global" tag="span">
            <template #objective>
              <b class="cp-DescriptionOkrElement">{{ $t('okr_elements.objective') }}</b>
            </template>
            <template #keyResult>
              <b class="cp-DescriptionOkrElement">{{ $t('element.type.key_result') }}</b>
            </template>
          </i18n-t>
        </AppCheckbox>
        <AppDivider class="cp-Divider" no-margin />
      </div>

      <div class="cp-OptionWrapper">
        <AppTitle :level="4" class="cp-OptionTitle" disable-margin>
          {{ $t('settings.company.dashboard_visibility_title') }}
        </AppTitle>

        <div class="cp-MultipleOptions">
          <AppCheckbox
            v-for="dashboard in dashboardsVisibility"
            :key="dashboard.parameter"
            v-model="dashboard.modelValue"
            :data-auto-testid="dashboard.testId"
            :data-testid="dashboard.testId"
            align-start
            @update:model-value="
              saveSettingsParameter({
                parameter: dashboard.parameter,
                value: $event
              })
            "
          >
            <i18n-t keypath="settings.company.dashboard_switch_label" scope="global">
              <template #dashboardName>
                <b>
                  {{ $t(dashboard.name) }}
                </b>
              </template>
            </i18n-t>
          </AppCheckbox>

          <p class="cp-Description cp-Description-no-margin">
            {{ $t('settings.company.dashboard_visibility_description') }}
          </p>
        </div>

        <AppDivider class="cp-Divider" no-margin />
      </div>

      <div class="cp-OptionWrapper">
        <AppTitle :level="4" class="cp-OptionTitle" disable-margin>
          {{ $t('settings.company.homepage_title') }}
        </AppTitle>
        <AppCheckbox
          v-model="hideOtherUsersAndGroupsOnHomepage"
          align-start
          data-auto-testid="block-exploring-others-switch"
          data-testid="block-exploring-others-switch"
          @update:model-value="
            saveSettingsParameter({
              parameter: COMPANY_SETTINGS_ENTITY_KEYS.HIDE_OTHER_USERS_AND_GROUPS_ON_HOMEPAGE,
              value: $event
            })
          "
        >
          {{ $t('settings.company.homepage_switch') }}

          <i18n-t
            class="cp-Description"
            keypath="settings.company.homepage_description"
            scope="global"
            tag="p"
          >
            <template #break>
              <br />
            </template>
          </i18n-t>
        </AppCheckbox>

        <AppDivider class="cp-Divider" no-margin />
      </div>

      <div class="cp-OptionWrapper">
        <AppTitle :level="4" class="cp-OptionTitle" disable-margin>
          {{ $t('settings.company.default_states_title') }}
        </AppTitle>
        <AppCheckbox
          v-model="objectiveContributeByDefault"
          align-start
          data-auto-testid="objective-contribute-by-default-switch"
          data-testid="objective-contribute-by-default-switch"
          @update:model-value="
            saveSettingsParameter({
              parameter: COMPANY_SETTINGS_ENTITY_KEYS.OBJECTIVE_CONTRIBUTE_BY_DEFAULT,
              value: $event
            })
          "
        >
          <i18n-t
            keypath="settings.company.default_states_okr_switch_entity"
            scope="global"
            tag="span"
          >
            <template #entity>
              <b class="cp-DescriptionOkrElement">{{ $t('okr_elements.objective') }}</b>
            </template>
          </i18n-t>

          <p class="cp-Description">
            {{ $t('settings.company.default_states_okr_description') }}
          </p>
        </AppCheckbox>

        <UseAppPlatform v-slot="{ isJiraApp }">
          <div v-if="isJiraApp" class="cp-IssueSettings">
            <AppCheckbox
              v-model="taskContributeByDefault"
              align-start
              data-auto-testid="task-contribute-by-default-switch"
              data-testid="task-contribute-by-default-switch"
              @update:model-value="
                saveSettingsParameter({
                  parameter: COMPANY_SETTINGS_ENTITY_KEYS.TASK_CONTRIBUTE_BY_DEFAULT,
                  value: $event
                })
              "
            >
              <i18n-t
                keypath="settings.company.default_states_tasks_switch_entity"
                scope="global"
                tag="span"
              >
                <template #entity>
                  <b class="cp-DescriptionOkrElement">{{ $t('dashboard.jira_issue_linked') }}</b>
                </template>
              </i18n-t>

              <p class="cp-Description">
                {{ $t('settings.company.default_states_tasks_description') }}
              </p>
            </AppCheckbox>
          </div>
        </UseAppPlatform>

        <AppDivider class="cp-Divider" no-margin />
      </div>
      <div class="cp-OptionWrapper">
        <AppTitle :level="4" class="cp-OptionTitle" disable-margin>
          {{ $t('settings.company.okr_privacy_settings_title') }}
        </AppTitle>
        <AppCheckbox
          v-model="allowWsAdminToSeePrivateObj"
          align-start
          data-auto-testid="ws-admin-see-private-switch"
          data-testid="ws-admin-see-private-switch"
          @update:model-value="
            saveSettingsParameter({
              parameter: COMPANY_SETTINGS_ENTITY_KEYS.ALLOW_WS_ADMIN_TO_SEE_PRIVATE_OBJECTIVES,
              value: $event
            })
          "
        >
          <i18n-t
            keypath="settings.company.okr_privacy_settings_switch"
            scope="global"
            tag="span"
          />
          <p class="cp-Description">
            {{ $t('settings.company.okr_privacy_settings_description') }}
          </p>
        </AppCheckbox>

        <AppDivider class="cp-Divider" no-margin />
      </div>

      <div class="cp-OptionWrapper cp-OptionWrapper_UserWrapper">
        <AppTitle :level="4" class="cp-OptionTitle" disable-margin>
          {{ $t('settings.company.default_users_role_title') }}
        </AppTitle>

        <DefaultRoleSelect
          v-model="userRoleByDefault"
          :options="resolvedRoles"
          @update:model-value="
            saveSettingsParameter({
              parameter: COMPANY_SETTINGS_ENTITY_KEYS.USER_ROLE_BY_DEFAULT,
              value: $event
            })
          "
        >
          <template #description>
            <p class="cp-Description">
              {{ $t('settings.company.default_user_role_description') }}
            </p>
          </template>
        </DefaultRoleSelect>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { useCompanySettings } from '@/utils/company-settings'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { COMPANY_SETTINGS_ENTITY_KEYS } from '@/utils/entity-keys'

import DefaultRoleSelect from '@/components/roles/DefaultRoleSelect'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppDivider from '@/components/ui/AppDivider'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import UseAppPlatform from '@/components/UseAppPlatform'
import OrganizationNameSettings from '@/views/workspaces/settings/OrganizationNameSettings'

import 'vue-slider-component/theme/default.css'

const dashboardsVisibility = reactive([
  {
    testId: 'show-general-dashboard-to-users-switch',
    name: 'dashboard.menu.general_report',
    parameter: COMPANY_SETTINGS_ENTITY_KEYS.SHOW_GENERAL_DASHBOARD_TO_USERS,
    modelValue: false
  },
  {
    testId: 'show-performance-dashboard-to-users-switch',
    name: 'dashboard.menu.performance_report',
    parameter: COMPANY_SETTINGS_ENTITY_KEYS.SHOW_PERFORMANCE_DASHBOARD_TO_USERS,
    modelValue: false
  },
  {
    testId: 'show-custom-dashboard-to-users-switch',
    name: 'dashboard.menu.custom_dashboard',
    parameter: COMPANY_SETTINGS_ENTITY_KEYS.SHOW_CUSTOM_DASHBOARD_TO_USERS,
    modelValue: false
  }
])

const linkObjToKR = ref(false)
const hideOtherUsersAndGroupsOnHomepage = ref(false)
const objectiveContributeByDefault = ref(false)
const taskContributeByDefault = ref(false)
const allowWsAdminToSeePrivateObj = ref(false)

const store = useStore()

const settings = computed(() => store.state.system.settings)
const isPluginAdmin = computed(() => store.state.pluginOptions.isPluginAdmin)

const userRoleByDefault = ref(null)

const { saveSettingsParameter, resolvedRoles } = useCompanySettings()

const router = useRouter()

onMounted(async () => {
  if (!isPluginAdmin.value) {
    router.push('/')
    return
  }

  dashboardsVisibility.forEach(dashboard => {
    dashboard.modelValue = settings.value[dashboard.parameter] || false
  })

  linkObjToKR.value = settings.value.linkObjToKR
  hideOtherUsersAndGroupsOnHomepage.value = settings.value.hideOtherUsersAndGroupsOnHomepage
  objectiveContributeByDefault.value = settings.value.objectiveContributeByDefault
  taskContributeByDefault.value = settings.value.taskContributeByDefault
  userRoleByDefault.value = settings.value.userRoleByDefault

  allowWsAdminToSeePrivateObj.value = settings.value.allowWsAdminToSeePrivateObj
})
</script>

<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CompanySettings'
})
</script>
<style lang="scss" scoped>
.cp-CompanyPage {
  padding-bottom: $page-bottom-padding;
  width: 100%;
  max-width: $page-width-lg;
}

.cp-MainContent {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  width: 664px;
  font-family: $system-ui;
  --label-color: #{$dark-3};
}

.cp-OptionTitle {
  font-family: $system-ui;
  margin-bottom: 20px;
  font-weight: fw('semi-bold');
}

.cp-Description {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;

  &:not(&-no-margin) {
    margin: 8px 0 0;
  }

  &-no-margin {
    margin: 0;
  }

  //&-Grading {
  //  margin-top: 0;
  //}
}

.cp-Divider {
  margin-top: 40px;
}

.cp-OptionWrapper {
  margin-bottom: 40px;
}

.cp-MultipleOptions {
  display: grid;
  gap: 20px;
}

.cp-OptionWrapper_UserWrapper {
  max-width: 400px;
}

.cp-IssueSettings {
  margin-top: 20px;
}

.cp-DescriptionOkrElement {
  color: $dark-2;
  font-weight: fw('semi-bold');
}
</style>
