import { computed } from 'vue'
import { useStore } from 'vuex'

import { APP_MODULE_IDS } from '@root/disabled-moduels'

export const useDisabledModules = () => {
  const store = useStore()

  const disabledModules = computed(() => store.state.appContext.disabledModules)

  const isBillingModuleDisabled = computed(() => {
    return store.getters['appContext/isModuleDisabled']({
      moduleId: APP_MODULE_IDS.BILLING
    })
  })

  const isConnectionDisconnectionModuleDisabled = computed(() => {
    return store.getters['appContext/isModuleDisabled']({
      moduleId: APP_MODULE_IDS.CONNECTION_DISCONNECTION_MODULE
    })
  })

  return {
    disabledModules,
    isBillingModuleDisabled,
    isConnectionDisconnectionModuleDisabled
  }
}
