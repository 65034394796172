<template>
  <div
    :class="{
      [`aim-Message-${type}`]: true
    }"
    class="aim-Message"
  >
    <AppIcon
      v-if="type !== NONE"
      :icon-name="icon"
      class="aim-Message_Icon"
      height="24"
      width="24"
    />
    <div class="aim-Message_Text">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'AppInfoMessage'
})

const props = defineProps({
  type: {
    type: String,
    default: INFO_MESSAGE_TYPES.DEFAULT,
    validator: v => Object.values(INFO_MESSAGE_TYPES).includes(v)
  }
})

const { DEFAULT, SUCCESS, WARNING, ERROR, NONE } = INFO_MESSAGE_TYPES

const INFO_MESSAGE_ICONS = {
  [DEFAULT]: 'info-next',

  [WARNING]: 'info-warning',

  [SUCCESS]: 'info-success',

  [ERROR]: 'info-error'
}

const icon = computed(() => INFO_MESSAGE_ICONS[props.type])
</script>

<style lang="scss" scoped>
.aim-Message {
  grid-template-columns: 24px 1fr;
  gap: 8px;
  max-width: 100%;
  background-color: var(--bg-color, transparent);
  padding: var(--padding, 0);
  border-radius: var(--border-radius, 0);

  &:not(&-none) {
    display: grid;
  }
}

.aim-Message_Icon {
  color: $grey-1-next;
}

.aim-Message_Text {
  padding-top: var(--text-padding-top, 4px);
  font-style: normal;
  font-weight: fw('regular');
  font-size: var(--font-size, #{$fs-12});
  line-height: var(--line-height, 16px);
  color: var(--color, #{$dark-3});
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: var(--text-max-width, 100%);
}
</style>
