export default {
  "app.platform.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira"])},
  "app.platform.web_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web App"])},
  "app.platform.both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both platforms"])},
  "action.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "action.delete_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("entity"))])},
  "action.remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "action.remove_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove ", _interpolate(_named("entity"))])},
  "action.revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke"])},
  "action.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
  "action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "action.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "action.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "action.accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "action.learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
  "action.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "action.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "action.created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "action.deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
  "action.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "action.updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
  "action.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "action.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
  "action.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "action.restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
  "action.restored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restored"])},
  "action.proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
  "action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "action.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
  "action.load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
  "action.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "action.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "action.claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim"])},
  "action.connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
  "action.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
  "action.unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink"])},
  "action.lets_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s start"])},
  "action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "action.get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started"])},
  "action.merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge"])},
  "action.activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
  "action.inactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivate"])},
  "action.remove_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from ws"])},
  "action.remove_from_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from group"])},
  "action.add_to_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to group"])},
  "action.delete_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
  "action.add_column_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert column right"])},
  "action.add_column_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert column before"])},
  "action.add_row_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert row above"])},
  "action.add_row_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert row below"])},
  "action.delete_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete row"])},
  "action.delete_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete column"])},
  "action.merge_or_split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge or split cells"])},
  "action.table_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table options"])},
  "action.delete_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete table"])},
  "action.toggle_header_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle header column"])},
  "action.toggle_header_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle header row"])},
  "action.toggle_header_cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle header cell"])},
  "action.add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add link"])},
  "action.add_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add video"])},
  "action.add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add users"])},
  "action.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])},
  "action.code_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code snippet"])},
  "action.mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mention"])},
  "action.quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote"])},
  "action.emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
  "action.add_user_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Add user"]), _normalize(["Add users"])])},
  "action.unlink_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink groups"])},
  "action.import_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import users"])},
  "action.link_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Link ", _interpolate(_named("platform")), " groups"])},
  "action.create_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create dashboard"])},
  "action.add_to_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to favorites"])},
  "action.remove_from_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from favorites"])},
  "action.remove_from_dashboard": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove ", _interpolate(_named("elementName")), " from dashboard ", _interpolate(_named("customDashboardName")), "?"])},
  "action.share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "action.change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change status"])},
  "action.leave_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave review"])},
  "action.grant_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant web access"])},
  "action.revoke_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke web access"])},
  "action.remove_from_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from administrators"])},
  "action.grant_web_access.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Note that it will be", _interpolate(_named("break")), " charged separately based", _interpolate(_named("break")), " on Web app license."])},
  "action.select_user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Select user"]), _normalize(["Select users"])])},
  "action.select_user_or_email": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enter email or select user"]), _normalize(["Enter emails or select users"])])},
  "action.update_license_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update license status"])},
  "action.access_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access settings"])},
  "action.add_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add method"])},
  "action.add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add address"])},
  "action.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
  "action.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another"])},
  "action.bulk_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk edit"])},
  "action.enable_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable contribution"])},
  "action.disable_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable contribution"])},
  "action.youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YouTube"])},
  "action.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divider"])},
  "action.task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
  "action.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "datepicker.select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
  "datepicker.select_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date range"])},
  "datepicker.without_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without date"])},
  "datepicker.next": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["next ", _interpolate(_named("days")), " days"])},
  "datepicker.previous": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["previous ", _interpolate(_named("days")), " days"])},
  "date.custom_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom date"])},
  "date.date_with_word_break": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " ", _interpolate(_named("break")), " — ", _interpolate(_named("end"))])},
  "create.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new objective"])},
  "create.objective.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add objective"])},
  "create.objective.btn.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select objective"])},
  "create.objective.btn.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "create.okr_element.create_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create another"])},
  "create.objective.success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objective created"])},
  "create.objective.success_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the filters if you don’t see it"])},
  "weights.objective.manual.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("mode")), " To preserve your custom OKR weights, all new OKRs will be added with a weight of 0. Their weight can be changed after."])},
  "weights.objective.manual_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual mode."])},
  "weights.objective.cant_change_weight_desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can’t change weights in ", _interpolate(_named("status")), " objective. \n Reopen objective to edit custom weights"])},
  "weights.objective.cant_change_weight_by_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available in Professional subscription plan or higher."])},
  "weights.objective.cant_change_weight_by_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use custom weights you need to have at least 1 nested element."])},
  "create.keyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key result name"])},
  "action.create_keyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create key result"])},
  "update.keyResult.result_type_lock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The grade cannot be edited as it’s calculated based on nested items."])},
  "update.keyResult.result_type_lock_message_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you want to return to manual mode, ", _interpolate(_named("action")), " of all the nested items"])},
  "update.keyResult.result_type_lock_message_description_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["switch off contribution"])},
  "create.keyResult.label.originalValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "create.keyResult.label.currentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
  "create.keyResult.label.targetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
  "create.keyResult.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key result created"])},
  "action.link_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Jira-issue"])},
  "create.task.label.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "create.task.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-issue linked"])},
  "field.not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not set"])},
  "field.labels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels"])},
  "field.labels.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add label…"])},
  "field.labels.bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select label"])},
  "field.assignee.stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stakeholders"])},
  "filed.assignee.stakeholders.plug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add stakeholder…"])},
  "field.assignee.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "field.assignee.stakeholders.bulk_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select stakeholder"])},
  "field.group.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "field.groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "field.owner.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select owner…"])},
  "field.group.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set group…"])},
  "field.period.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "field.status.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "field.quoter.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval"])},
  "field.quoter.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set interval…"])},
  "field.parentObjective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent objective"])},
  "field.parentItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent item"])},
  "field.parent_element": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent element"])},
  "field.issue.name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Jira-issue by Name or Full ID"])},
  "field.issue.jql_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter JQL query"])},
  "field.select.nooptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No options"])},
  "field.select.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "field.contribute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribute to parent's grade"])},
  "field.workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name should be min 2 symbols"])},
  "field.workspace_key_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key should be 1-3 symbols"])},
  "field.interval_wrong_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong keyword"])},
  "field.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
  "field.name_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, this name is reserved"])},
  "field.check_above_to_proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept above to proceed"])},
  "field.workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace"])},
  "fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required fields"])},
  "placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "placeholder.search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
  "placeholder.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
  "placeholder.search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search user"])},
  "users.table_header_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "users.table_header_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "users.all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All workspaces"])},
  "users.bulk_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk add"])},
  "objectives.table_header_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives and Key Results"])},
  "objectives.table_header_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
  "objectives.table_header_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgt"])},
  "objectives.table_header_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "objectives.table_header_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "objectives.table_header_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval"])},
  "objectives.table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "objectives.table_header_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "objectives.table_header_duedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
  "objectives.table_header_startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
  "objectives.table_header_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
  "objectives.table_header_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels"])},
  "objectives.table_header_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stakeholders"])},
  "objectives.table_header_lastGradeUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last grade update"])},
  "objectives.table_header_gradeUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade updated"])},
  "objectives.table_header_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "objectives.table_header_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribute"])},
  "objectives.table_header_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
  "objectives.table_header_deleted_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted by"])},
  "objectives.table_header_deleted_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted at"])},
  "objectives.krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRs"])},
  "objectives.unavailable_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don’t have access to this Objective. Please contact your Jira administrator."])},
  "objectives.type_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "objectives.type_PERSONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
  "objectives.type_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "objectives.table_header_currentTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current / Target"])},
  "objectives.percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percent"])},
  "objectives.binary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binary"])},
  "objectives.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "action.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "objectives.predicted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Predicted ", _interpolate(_named("symbol"))])},
  "objectives.status_indicator.description.predicted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("predicted")), " – Predicted score (", _interpolate(_named("grade")), "/", _interpolate(_named("passed")), ")*100"])},
  "objectives.status_indicator.description.grade": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("grade")), " – Current grade"])},
  "objectives.status_indicator.description.passed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("passed")), " – Time have passed"])},
  "objectives.last_count_items_shown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last ", _interpolate(_named("count")), " ", _interpolate(_named("entity")), " are shown. To view more, please search using the full ID or name."])},
  "objectives.okr": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["OKR"]), _normalize(["OKRs"])])},
  "objective.you_cant_make_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can’t make the objective private.\nIt has to be assigned or has a stakeholder"])},
  "objective.make_okr_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make visible for owners and stakeholders only"])},
  "objective.make_okr_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make visible for everyone"])},
  "objective.privacy_cant_adjusted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Privacy can’t be adjusted for ", _interpolate(_named("closedNameStatus")), "/", _interpolate(_named("abandonedNameStatus")), " status"])},
  "objective.you_dont_have_rights_to_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don’t have rights to edit an element"])},
  "objective.btn.custom_weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom weights"])},
  "objective.link_to_parent_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to parent OKR"])},
  "objective.link_to_another_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to another OKR"])},
  "objective.add_key_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add key result"])},
  "objective.no_key_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No key results"])},
  "objective.no_key_results_for_selected_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no Key results available for selected Objective."])},
  "objective.btn.add_nested_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add nested objective"])},
  "objective.btn.no_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No nested items"])},
  "objective.modal_title.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter objective name"])},
  "objective.activity_log_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No activity log yet"])},
  "objective.nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nested objectives"])},
  "objective.key_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key results"])},
  "objective.incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete"])},
  "objective.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
  "objective.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate:"])},
  "objective.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weights:"])},
  "objective.a_moment_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A moment ago"])},
  "objective.hours_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours ago"])},
  "objective.columns_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns settings"])},
  "objective.columns_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns"])},
  "objective.select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Level"])},
  "objective.select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Group"])},
  "objective.select_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select owner"])},
  "objectives.days_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days ago"])},
  "objective.search_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by full id or a name"])},
  "objective.no_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No owner"])},
  "objectives.due_date.end_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of interval"])},
  "objectives.due_date.start_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start of interval"])},
  "objectives.period_mode.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period mode"])},
  "objective.sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "objective.by_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By status"])},
  "objective.level_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The OKR level is missing. Please contact us at <a href='mailto:support", "@", "oboard.io'><strong>support", "@", "oboard.io</strong></a>"])},
  "objective.sorting_switch_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Including nested elements"])},
  "objective.sorting_drag_blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(no drag’n’drop)"])},
  "objective.btn.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have read-only rights"])},
  "objective.chart_table.split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split by contributors"])},
  "objective.chart.progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "common.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "common.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "common.direct_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct link"])},
  "common.copy_link_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the link to this content and share with your colleagues."])},
  "common.custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom"])},
  "common.select_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select assignee"])},
  "common.select_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("entity"))])},
  "common.on_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["On ", _interpolate(_named("entity"))])},
  "common.and_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["and ", _interpolate(_named("entity"))])},
  "common.of_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["of ", _interpolate(_named("entity"))])},
  "common.on_the_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["On the ", _interpolate(_named("entity"))])},
  "common.fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullscreen"])},
  "common.sideview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sideview"])},
  "common.repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
  "common.on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
  "common.at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
  "common.and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "common.every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["every"])},
  "objective.chart.daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
  "objective.chart.weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
  "objective.chart.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
  "objective.chart.quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarterly"])},
  "objective.chart.years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])},
  "objective.chart.downloadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
  "objective.chart.download_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download .xlsx"])},
  "objective.chart.fullSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full size"])},
  "objective.chart.fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full screen"])},
  "objective.chart.no_items_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items selected"])},
  "objective.move_objective": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Move ", _interpolate(_named("displayId")), " to another WS"])},
  "objective.move_objective_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to move Objective:"])},
  "objective.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move nested items"])},
  "objective.obj_cant_move": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The objective cannot be moved with nested elements as one of the nested items is ", _interpolate(_named("closedStatus")), " or ", _interpolate(_named("abandonedStatus"))])},
  "objective.cant_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can’t move objective"])},
  "objective.obj_cant_move_because_kr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The objective cannot be moved as one of the nested items is ", _interpolate(_named("closedStatus")), " or ", _interpolate(_named("abandonedStatus"))])},
  "objective.move_all_count_okrs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Move all ", _interpolate(_named("count")), " OKR"]), _normalize(["Move all ", _interpolate(_named("count")), " OKRs"])])},
  "objective.you_have_no_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no rights to get element data"])},
  "intervals.backlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put your items to the backlog if the interval is not defined for them."])},
  "intervals.backlog_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store OKRs without defined interval."])},
  "kr.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nested items"])},
  "kr.add_nested_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add nested item"])},
  "kr.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move nested items. From current workspace only."])},
  "kr.move_from_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move from all workspaces."])},
  "move.select_assignee_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We haven’t found respective owner in the target workspace, please map them out."])},
  "move.select_groups_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We haven’t found respective groups in the target workspace, please map them out."])},
  "move.select_levels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We haven’t found respective levels in the target workspace, please map them out."])},
  "move.copy_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy nested objectives"])},
  "objective.clear_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear group"])},
  "move.success_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success moved"])},
  "move.no_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No parent item"])},
  "intervals.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "intervals.status.archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
  "intervals.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interval name"])},
  "intervals.new_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New interval"])},
  "intervals.create_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create interval"])},
  "intervals.edit_interval": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edit interval ", _interpolate(_named("intervalName"))])},
  "intervals.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No intervals found"])},
  "workspaces.new_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New workspace"])},
  "workspaces.edit_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit workspace"])},
  "workspaces.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage workspaces"])},
  "workspaces.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
  "workspaces.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create workspace"])},
  "workspaces.no_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don’t have rights to create a new workspace. Please contact your administrator"])},
  "workspaces.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces yet"])},
  "workspaces.no_workspaces_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first workspace to get started"])},
  "workspaces.workspace_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace created."])},
  "workspaces.workspace_field_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " updated successfully"])},
  "workspaces.page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select workspace to add users, groups and intervals."])},
  "workspaces.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "workspace.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace name"])},
  "workspace.label.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "workspace.label.add_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add description"])},
  "workspace.label.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
  "workspaces.key.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This workspace key used as the prefix of your OKRs in the workspace e.g. TE-O-203"])},
  "workspaces.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace name"])},
  "workspace.select_timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select timezone"])},
  "workspace.timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timezone"])},
  "common.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "workspaces.users_from_platform": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Users from linked ", _interpolate(_named("platform")), " groups"])},
  "workspaces.linked_user_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked users can not be removed manually"])},
  "workspaces.delete_user_from_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("userName")), " from group ", _interpolate(_named("groupName")), "?"])},
  "workspaces.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role in the workspace"])},
  "workspaces.intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervals"])},
  "workspaces.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workspaces found"])},
  "workspaces.groups_tab.linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Linked ", _interpolate(_named("platform")), " groups"])},
  "workspaces.groups_tab.linked_groups.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The groups where we get a list users to add into the plugin."])},
  "workspaces.table.cell.users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " User"]), _normalize([_interpolate(_named("count")), " Users"])])},
  "workspaces.table.cell.groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Group"]), _normalize([_interpolate(_named("count")), " Groups"])])},
  "workspaces.table.cell.intervals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Interval"]), _normalize([_interpolate(_named("count")), " Intervals"])])},
  "workspace.change_role": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Change role for user"]), _normalize(["Change role for ", _interpolate(_named("qty")), " users"])])},
  "workspaces.private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
  "workspaces.public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
  "workspaces.visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
  "workspaces.no_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups yet"])},
  "workspaces.no_groups.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a group to add people to the workspace"])},
  "workspaces.add_users_to_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add users to ", _interpolate(_named("group"))])},
  "workspaces.create_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a group"])},
  "workspaces.select_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("platform")), " groups"])},
  "workspaces.select_linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select linked ", _interpolate(_named("platform")), " groups"])},
  "workspaces.manage_workspace.access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage workspace access and roles for all users"])},
  "groups.no_groups_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no groups in this Workspace."])},
  "groups.unlink.keep_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep users"])},
  "groups.unlink.keep_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keep users in ", _interpolate(_named("group")), " after unlinking ", _interpolate(_named("platform")), " group."])},
  "groups.unlink.delete_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete users"])},
  "groups.unlink.delete_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete users from ", _interpolate(_named("group")), " after unlinking ", _interpolate(_named("platform")), " group."])},
  "groups.unlink.strategy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select option"])},
  "groups.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups match this search"])},
  "groups.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create group"])},
  "groups.new_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New group"])},
  "groups.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename group"])},
  "groups.users_tab.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User groups. Default group will be set automatically when a user creates OKR and for the filter “My Group“."])},
  "groups.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete group"])},
  "group.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name"])},
  "group.group_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group by"])},
  "group.table_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table view"])},
  "group.select_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select workspace"])},
  "group.user_will_be_added_to_the_workspaces": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["The user will be added to the workspaces:"]), _normalize(["The users will be added to the workspaces:"])])},
  "users.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
  "users.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite user"])},
  "users.add_to_workspace": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Add user to workspace"]), _normalize(["Add users to workspace"])])},
  "users.add_to_workspace_without_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User will be added without any group"]), _normalize(["Users will be added without any group"])])},
  "users.add_to_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Add user to group"]), _normalize(["Add users to groups"])])},
  "users.add_to_group_userName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Add ", _interpolate(_named("userName")), " to group"]), _normalize(["Add ", _interpolate(_named("userName")), " to groups"])])},
  "users.add_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add workspace"])},
  "users.change_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change role"])},
  "users.change_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change default group"])},
  "users.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another user"])},
  "users.import_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import users from the Jira group once. Imported users can be removed manually at any time"])},
  "users.link_jira_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Jira groups"])},
  "users.link_jira_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link a Jira group to the OKR board. All changes to the Jira group will be pulled into the OKR board group automatically. Linked users can not be removed manually"])},
  "users.select_jira_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Jira group"])},
  "users.select_import_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select import options"])},
  "users.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There aren't any users matching the applied filters."])},
  "user.selected_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected groups"])},
  "user.group.sync.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The group cannot be selected as it imports users from associated ", _interpolate(_named("platform")), " groups."])},
  "user.personal_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal settings"])},
  "user.personal_settings.dont_have_email_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We don’t have access to your email address"])},
  "user.personal_settings.please_contact_our": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please contact our ", _interpolate(_named("contact")), " for more information"])},
  "user.personal_settings.when_someone_reply_me": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When someone ", _interpolate(_named("mention")), " and ", _interpolate(_named("reply")), " to you"])},
  "user.personal_settings.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reply"])},
  "user.personal_settings.mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", "mentions"])},
  "user.personal_settings.you_are_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’re the owner"])},
  "user.personal_settings.you_are_stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’re the stakeholder"])},
  "user.personal_settings.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "user.personal_settings.product_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll send you a newsletter announcing essential product updates in Oboard."])},
  "user.personal_settings.product_case_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips on collaboration, case studies, tutorials and many insightful articles."])},
  "user.personal_settings.product_promos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcing promos and upcoming events, and webinars."])},
  "user.personal_settings.about_qty_per_month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["About ", _interpolate(_named("qty")), " per month"])},
  "user.personal_settings.about_qty_every_months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["About ", _interpolate(_named("qty")), " every ", _interpolate(_named("repeat")), " months"])},
  "user.personal_settings.check_in_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A check-in is scheduled by the OKR admin"])},
  "user.preferences.user.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
  "user.preferences.user.email.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "user.preferences.change_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "user.preferences.default_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Default group"]), _normalize(["Default groups"])])},
  "user.preferences.no_groups_for_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not belong to any group in the workspace"])},
  "user_details.platform_profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("platform")), " profile"])},
  "user_details.delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To delete user from ", _interpolate(_named("workspaceName")), " please remove him from the respective synced ", _interpolate(_named("platform")), " Group."])},
  "user_details.delete_user_from_synced_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To delete user please remove them from the respective synced ", _interpolate(_named("platform")), " Group."])},
  "users.delete_user_from_plugin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To delete selected users please remove them from the respective synced ", _interpolate(_named("platform")), " Group."])},
  "user_details.add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to workspace"])},
  "user_details.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to another workspace"])},
  "user_details.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user is not in any workspace"])},
  "user_details.select_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select groups"])},
  "admins.platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
  "administrators.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrators can create, delete and change workspaces, groups, invite people and see all the OKRs in all the workspaces."])},
  "create.admin.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add administrator"])},
  "admin.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select user"])},
  "filter.this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This week"])},
  "filter.this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month"])},
  "filter.search_okr_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search OKR"])},
  "filter.manage_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage intervals"])},
  "filter.all_frequencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All frequencies"])},
  "filter.all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All groups"])},
  "filter.all_platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All platforms"])},
  "filter.all_grade_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All statuses"])},
  "filter.all_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All roles"])},
  "filter.all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users"])},
  "filter.all_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All owners"])},
  "filter.all_okr_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All OKR levels"])},
  "filter.all_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All labels"])},
  "filter.all_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All stakeholders"])},
  "filter.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "filter.selected_segment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected segment"])},
  "filter.clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear selection"])},
  "filter.clear_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear selected"])},
  "filter.expand_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand All"])},
  "filter.only_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only this"])},
  "filter.collapse_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse All"])},
  "filter.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link to the clipboard"])},
  "filter.no_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No OKRs were found to match your search."])},
  "filter.no_objective_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Try modifying your search criteria or ", _interpolate(_named("creating"))])},
  "filter.creating_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creating a new objective."])},
  "filter.no_objective_jira_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No OKRs were found to match your search. Try modifying your search criteria."])},
  "filter.no_objective_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No objectives in this interval"])},
  "filter.no_objective_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first objective to get started"])},
  "filter.custom_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom range"])},
  "filter.custom_range.between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Between"])},
  "filter.custom_range.since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since"])},
  "filter.custom_range.before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before"])},
  "filter.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "filter.limitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limitation"])},
  "filter.show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
  "filter.more_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than"])},
  "filter.less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than"])},
  "filter.at_least_comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["At least ", _interpolate(_named("count")), " comment"]), _normalize(["At least ", _interpolate(_named("count")), " comments"])])},
  "filter.never_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never commented"])},
  "filter.is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is empty"])},
  "filter.is_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is not empty"])},
  "common.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled"])},
  "common.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "common.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "common.getting_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting started"])},
  "common.complete_progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " out of ", _interpolate(_named("end"))])},
  "filter.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "filter.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "filter.last_grade_update_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade update"])},
  "filter.last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last comment"])},
  "filter.hide_filtered_items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Hide ", _interpolate(_named("count")), " filtered item"]), _normalize(["Hide ", _interpolate(_named("count")), " filtered items"])])},
  "filter.show_filtered_items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Show ", _interpolate(_named("count")), " filtered item"]), _normalize(["Show ", _interpolate(_named("count")), " filtered items"])])},
  "filter.all_are_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All objectives are filtered "])},
  "filter.show_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show them"])},
  "filter.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "search_criteria.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset search criteria"])},
  "filter.add_another_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another field "])},
  "filter.hide_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide KRs"])},
  "filter.show_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show KRs"])},
  "filter.sort_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
  "filter.clear_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
  "filter.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owners"])},
  "filter.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "filter.statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuses"])},
  "filter.levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels"])},
  "filter.okr_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR level"])},
  "filter.more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "filter.more_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More filters"])},
  "filter.avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg"])},
  "filter.A_Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Z"])},
  "filter.Z_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-A"])},
  "filter.start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
  "filter.due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
  "filters.update_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update filter"])},
  "filters.save_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save filter as"])},
  "filters.update_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update filter as"])},
  "filters.update_dashboard_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update dashboard as"])},
  "filters.save_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as..."])},
  "filters_saver.filter_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filter: ", _interpolate(_named("filterName")), " saved successfully"])},
  "filters_saver.filter_renamed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filter: ", _interpolate(_named("filterName")), " renamed successfully"])},
  "filters_saver.filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filter: ", _interpolate(_named("filterName")), " updated successfully"])},
  "filters.filter_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filter: ", _interpolate(_named("filterName")), " deleted successfully"])},
  "filters.custom_fields.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The saved filter has a custom field that is no longer available."])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selected"])},
  "action.create_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create objective"])},
  "action.create_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Jira-issue"])},
  "action.archive_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
  "action.reopen_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
  "action.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "action.edit_on_user_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit on user page"])},
  "dropdown.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
  "dropdown.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom weights"])},
  "action.unlink_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink issue"])},
  "dropdown.sync_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync now"])},
  "dropdown.export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export csv"])},
  "action.paste_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste link"])},
  "action.paste_yt_video_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste YouTube video link"])},
  "dropdown.viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewer"])},
  "dropdown.link_new_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Link new ", _interpolate(_named("platform")), " groups"])},
  "dropdown.unlink_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unlink ", _interpolate(_named("platform")), " groups"])},
  "dropdown.restore_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore default"])},
  "confirm.discard_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard"])},
  "confirm.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link it"])},
  "confirm.dontLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't link"])},
  "confirm.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete objective"])},
  "confirm.objective.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete nested items"])},
  "confirm.kr.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete KR"])},
  "delete_user.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Delete ", _interpolate(_named("userName"))]), _normalize(["Delete Users"])])},
  "delete_user.count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("count")), " users"])},
  "confirm.user.message": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Are you sure you want to delete the user?"]), _normalize(["Are you sure you want to delete the users?"])])},
  "confirm.delete_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("entity")), "?"])},
  "delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("userName")), " from workspace: ", _interpolate(_named("workspaceName")), "?"])},
  "delete_user_from_app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("userName")), " from ", _interpolate(_named("appName")), "?"])},
  "confirm.admin.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove ", _interpolate(_named("userName")), " from administrators"])},
  "confirm.admin.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure to remove ", _interpolate(_named("userName")), " from administrators? The user won’t be deleted from the plugin if he’s added to at least one workspace."])},
  "confirm.interval_change_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This will reset the ", _interpolate(_named("dateProp")), " value to the value of the new interval."])},
  "delete_confirm_workspace_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete workspace"])},
  "delete_confirm_workspace_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete workspace ", _interpolate(_named("workspaceName")), " and all OKRs from this workspace?"])},
  "delete_confirm_interval_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete interval"])},
  "delete_confirm_interval_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete interval ", _interpolate(_named("intervalName")), " and all OKRs from this interval?"])},
  "delete_confirm_interval_input_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Type ", _interpolate(_named("keyword")), " to confirm"])},
  "delete_confirm_objective_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete Objective:"])},
  "delete_confirm_kr_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete Key Result:"])},
  "delete_confirm_issue_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to unlink issue:"])},
  "delete_confirm_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke API token"])},
  "delete_confirm_token_message_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to permanently revoke your API token"])},
  "delete_confirm_token_message_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoking an API token is not reversible"])},
  "users.roles.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "users.roles.total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("qty")), " roles"])},
  "users.add_users_to_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Add selected user to workspace"]), _normalize(["Add ", _interpolate(_named("count")), " selected users to workspace"])])},
  "menu.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
  "menu.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "menu.dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "menu.workspaces.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings & intervals"])},
  "menu.support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help & support"])},
  "menu.admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrators"])},
  "menu.organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups & users"])},
  "menu.organization.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global groups & users"])},
  "menu.integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
  "menu.api_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API tokens"])},
  "menu.personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal settings"])},
  "menu.synchronization_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync settings"])},
  "menu.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
  "menu.custom_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom fields"])},
  "menu.okr_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
  "menu.comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "menu.comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
  "menu.activity_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity log"])},
  "menu.my_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My OKRs"])},
  "menu.my_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My groups OKRs"])},
  "menu.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "menu.tooltip.collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])},
  "menu.tooltip.expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
  "menu.tooltip.go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
  "menu.show_other_views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show other views"])},
  "menu.whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What’s new"])},
  "menu.security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
  "menu.branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])},
  "menu.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspaces"])},
  "menu.audit_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit logs"])},
  "menu.check_ins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-ins"])},
  "menu.bin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bin"])},
  "user_menu.profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "user_menu.switch_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change organization"])},
  "user_menu.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "user_menu.create_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new organization"])},
  "tour.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new objective"])},
  "tour.step1.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can create a new objective."])},
  "tour.step2.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new objective or KR"])},
  "tour.step2.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can add objective, key results or link you Jira-issue to an OKR."])},
  "tour.step3.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the grade"])},
  "tour.step3.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can change the progress for KR. Progress of Jira-issues is calculated automatically."])},
  "tour.step4.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add group members"])},
  "tour.step4.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your groupmates and manage groups."])},
  "tour.got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it!"])},
  "tour.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "tour.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip tour"])},
  "support.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support page"])},
  "support.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We also welcome suggestions about our plugin, \nfeature ideas and general comments"])},
  "support.btn_write_to_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write to support"])},
  "support.response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We try to respond to all support requests within 1 business day. Oboard is located in Central Europe and our official"])},
  "support.support_hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["support hours are ", _interpolate(_named("hours")), ", Monday - Friday (GMT+1)"])},
  "support.plugin_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plugin version:"])},
  "support.instance_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your instance ID:"])},
  "support.security_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud security program"])},
  "support.help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help center"])},
  "support.onboarding_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding call"])},
  "support.consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
  "support.okr_consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR \nconsulting"])},
  "support.video_guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video guides"])},
  "support.video_guides.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch our guides to make sure you use our app to the fullest"])},
  "support.video_guides.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play video"])},
  "support.support.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create a support ticket here or mail us at ", _interpolate(_named("emailAddress"))])},
  "support.help_center.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find answers to common questions in our Confluence Help Center."])},
  "support.help_center.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find answers to common questions."])},
  "support.onboarding_call.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your fist 90 days OKR cycle with full product support."])},
  "support.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost your OKR learning curve with Oboard's OKR consultants training, and workshops."])},
  "support.help_center.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit help center"])},
  "support.onboarding_call.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a call"])},
  "support.okr_consulting.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book consultation"])},
  "support.okr_consulting.link.text.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book free OKR review"])},
  "support.define_your_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Accelerator Program"])},
  "support.12_free_lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 free lessons"])},
  "support.define_your_okr.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerator is a dedicated OKR program of 12 weekly lessons that will guide you through the crucial first 90 days of OKR roll-out and offer the opportunity to become a certified OKR expert."])},
  "support.define_your_okr.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define your level"])},
  "support.okr_review.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a demo"])},
  "support.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "support.play_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play all"])},
  "statuses.page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename statuses for better alignment with your workflow."])},
  "statuses.status_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status name"])},
  "statuses.progress_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress statuses"])},
  "statuses.close_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close statuses"])},
  "statuses.close_statuses.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close status locks the object and prevents editing and changing the grade"])},
  "statuses.notifications.renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status renamed successfully"])},
  "statuses.rename_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename status"])},
  "release_updates.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open changelog"])},
  "release_updates.footer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more updates check out:"])},
  "breadcrumb.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
  "roles.not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not selected"])},
  "roles.suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended"])},
  "roles.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "roles.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "roles.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "roles.group_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group leader"])},
  "roles.workspace_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace admin"])},
  "roles.user_no_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User/no delete"])},
  "roles.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reader"])},
  "confirm_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard changes?"])},
  "confirm_interval_change.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm change of interval"])},
  "confirm_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can have unsaved data. Are you sure you want to discard all unsaved changes?"])},
  "dashboard.dashboard_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dashboard: ", _interpolate(_named("dashboardName")), " deleted successfully"])},
  "dashboard.reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
  "dashboard.average_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg.total"])},
  "dashboard.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "dashboard.no_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no owners yet"])},
  "dashboard.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no groups yet"])},
  "dashboard.no_owners_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No owners match this search"])},
  "dashboard.menu.general_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General dashboard"])},
  "dashboard.menu.performance_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance report"])},
  "dashboard.menu.custom_dashboard": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Custom dashboard"]), _normalize(["Custom dashboards"])])},
  "dashboard.assigned_to_all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assigned to all groups"])},
  "dashboard.assigned_to_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["assigned to the group"])},
  "dashboard.across_all_the_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Across all the groups"])},
  "dashboard.across_all_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["across all the group"])},
  "dashboard.by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By group"])},
  "dashboard.in_this_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this workspace"])},
  "dashboard.in_this_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this group"])},
  "dashboard.avg_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg progress"])},
  "dashboard.all_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
  "dashboard.days_left_in_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days left in the cycle"])},
  "dashboard.interval_passed_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cyclePassedPercent")), "% passed"])},
  "dashboard.people_with_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People with OKR"])},
  "dashboard.people_without_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People without OKR"])},
  "dashboard.no_linked_krs_nested_jira_issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w/o child KR, obj., Jira-issues"])},
  "dashboard.no_linked_krs_nested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No linked KRs, nested objectives"])},
  "dashboard.jira_issue_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-issues"])},
  "dashboard.linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linked"])},
  "dashboard.no_objectives_in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No objectives in the group"])},
  "dashboard.no_objectives_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No objectives yet"])},
  "dashboard.add_first_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the first objective to report"])},
  "dashboard.in_this_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in this cycle"])},
  "dashboard.last_qty_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last ", _interpolate(_named("qty")), " days"])},
  "dashboard.enter_the_main_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the main goal"])},
  "dashboard.access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied"])},
  "dashboard.you_dont_have_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permission to access one or more OKRs in this dashboard"])},
  "dashboard.layout_orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout orientation"])},
  "dashboard.horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
  "dashboard.vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
  "dashboard.add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more"])},
  "dashboard.created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created by ", _interpolate(_named("userName"))])},
  "dashboard.start_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start presentation"])},
  "dashboard.stop_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop presentation"])},
  "dashboard.export_as_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export as PDF"])},
  "dashboard.duplicate_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate dashboard"])},
  "dashboard.share_with_same_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share it with the same people"])},
  "dashboard.share_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share dashboard"])},
  "dashboard.share.public.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All public dashboards are visible to all users and available to duplicate.", _interpolate(_named("break")), _interpolate(_named("break")), " Make the dashboard private if you want to share it with specific people or it has sensitive data."])},
  "dashboard.add_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add people"])},
  "dashboard.custom_dashboard_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter custom dashboard name"])},
  "dashboard.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No custom dashboards"])},
  "dashboard.no_report_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first custom dashboard"])},
  "dashboard.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There aren't any dashboard matching the applied filters."])},
  "dashboard.add_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add custom dashboard"])},
  "dashboard.choose_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose custom dashboard"])},
  "dashboard.no_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard requires at least one opened interval"])},
  "dashboard.no_intervals_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a first interval to get started"])},
  "dashboard.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
  "dashboard.dashboard_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard name"])},
  "dashboard.add_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add objectives"])},
  "homepage.user_select.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team members"])},
  "homepage.user_select.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Units"])},
  "homepage.progress.last_week_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last week change"])},
  "homepage.progress.switch.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives’ progress"])},
  "homepage.progress.switch.krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key results’ progress"])},
  "homepage.objectives_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("groupName")), " objectives"])},
  "homepage.objectives.owner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "’s objectives"])},
  "homepage.objectives.foreign_objectives": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "’s KRs from foreign objectives"])},
  "homepage.objectives.stakeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " is stakeholder"])},
  "homepage.objectives.watcher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " is following"])},
  "homepage.user_summary.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "’s groups"])},
  "homepage.group_summary.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People in group"])},
  "homepage.okr_elements_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No objectives or key results for which ", _interpolate(_named("role"))])},
  "homepage.okr_elements_empty_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no objectives with key results assigned to you"])},
  "homepage.okr_elements_empty_foreign_objectives": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No key results assigned to ", _interpolate(_named("name"))])},
  "homepage.role.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you're an owner"])},
  "homepage.role.stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you're a stakeholder"])},
  "homepage.role.watcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you're an watcher"])},
  "homepage.role.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the group is an owner"])},
  "homepage.notify.error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Couldn’t find selected ", _interpolate(_named("role"))])},
  "homepage.notify.role.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
  "homepage.export_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export *.pdf"])},
  "homepage.key_result_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Key result"]), _normalize(["Key results"])])},
  "homepage.objective_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Objective"]), _normalize(["Objectives"])])},
  "tokens.create_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create token"])},
  "tokens.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can use an API token to perform authentication with OKR Board application. ", _interpolate(_named("break")), "You should treat API tokens as securely as any other password. You used ", _interpolate(_named("usedTokensCount")), " of 10 tokens."])},
  "tokens.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have reached the maximum number (", _interpolate(_named("limit")), ") of API tokens."])},
  "tokens.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token name"])},
  "tokens.token_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
  "tokens.no_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tokens yet"])},
  "tokens.no_tokens_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first public token API token"])},
  "token.create.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an API token"])},
  "token.create.label_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a label, that is short, memorable and easy for you to remember."])},
  "token.minutes_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes ago"])},
  "token.toast.revoke_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your API token has been revoked"])},
  "token.copy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new API token"])},
  "token.copy.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api token"])},
  "token.copy.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure you copy your new API token. You won't be able to see this token again."])},
  "auth.session_expired.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It has been a while since your last activity"])},
  "auth.session_expired.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please reload the page to get accurate data."])},
  "pagination.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "pagination.of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "pagination.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["owners"])},
  "pagination.entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entries"])},
  "unlink_jira_issue.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink Jira-issue"])},
  "link_jira_issue.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Jira-issue to key result"])},
  "link_jira_issue.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You want to link the contributing Jira-issue to key result."])},
  "link_jira_issue.it_means": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It means:"])},
  "link_jira_issue.list_item_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade will be calculated automatically according to Jira-issue status."])},
  "link_jira_issue.list_item_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot change the grade manually, unless you unlink issue or turn off the contribute option."])},
  "link_jira_issue.link_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link anyway?"])},
  "link_jira_issue.jql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JQL"])},
  "link_jira_issue.jql_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid JQL request"])},
  "link_jira_issue.jira_issues_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Jira-issues found."])},
  "link_jira_issue.check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open result in the new tab"])},
  "link_jira_issue.import_count_jira_issues": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We’ll import only the first ", _interpolate(_named("count")), " Jira-issues"])},
  "link_jira_issue.jql_timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request timeout"])},
  "link_jira_issue.linked_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked Jira-issue:"])},
  "link_jira_issue.link_another_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link another Jira-issue"])},
  "link_jira_issue.open_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open task in Jira"])},
  "interval.label.time_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time period"])},
  "interval.label.end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
  "tree.fit_content_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fit content size"])},
  "tree.switch_to_detailed_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to detailed card view"])},
  "tree.switch_to_compact_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to compact card view"])},
  "tree.legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legend"])},
  "tree.legend.show_child_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show child items"])},
  "tree.legend.show_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show filtered items"])},
  "tree.legend.hide_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide filtered items"])},
  "tree.legend.not_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn't contribute to parent's grade"])},
  "roadmap.quarter_shortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
  "roadmap.views.weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks"])},
  "roadmap.views.months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months"])},
  "roadmap.views.quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarters"])},
  "roadmap.updated_timeline_success_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dateProp")), " successfully updated."])},
  "roadmap.start_and_due_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start and due dates"])},
  "roadmap.backlog_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmap is not available for backlog interval"])},
  "roadmap.backlog_error.list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following OKRs or their nested items are in backlog interval"])},
  "roadmap.invalid_dates_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date should be less than Due Date"])},
  "roadmap.export.width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width of one part:"])},
  "roadmap.export.max_width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max width for image:"])},
  "roadmap.export.max_width_item": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["• ", _interpolate(_named("app")), " — ", _interpolate(_named("width")), " px"])},
  "roadmap.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "roadmap.export_option.visible.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible area"])},
  "roadmap.export_option.visible.hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export only what you see on the screen"])},
  "roadmap.export_option.full.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole roadmap"])},
  "roadmap.export_option.multiple.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export as multiple images"])},
  "roadmap.export.safari_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safari browser doesn't fully support export functionality. Please use Chrome or Firefox."])},
  "roadmap.export_beta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export roadmap (beta)"])},
  "status.switch_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
  "status.switch_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equal"])},
  "status.switch_auto_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto status"])},
  "status.switch_manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
  "status.backlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backlog"])},
  "status.none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "status.switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll change the status based on progress and timeline"])},
  "grade_info.total_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total grade"])},
  "grade_info.your_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your KRs"])},
  "grade_info.filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtered items"])},
  "custom_weights.contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribute"])},
  "custom_weights.weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
  "custom_weights.impact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact"])},
  "saving_indicator.saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving…"])},
  "saving_indicator.saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
  "levels.header_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR levels"])},
  "levels.page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels help to build custom hierarchy and available in filters, APIs, and exports."])},
  "levels.title.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create OKR level"])},
  "levels.title.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit OKR level"])},
  "levels.no_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No levels yet"])},
  "levels.no_levels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first OKR Level to get started"])},
  "levels.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level name"])},
  "levels.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id (1-2)"])},
  "levels.color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
  "levels.add_level_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add level to these workspaces"])},
  "levels.add_to_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to all workspaces"])},
  "levels.delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("levelName")), " level"])},
  "levels.delete_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To delete ", _interpolate(_named("levelName")), " level you need to convert all the ", _interpolate(_named("levelName")), " OKRs to any of the existing OKR levels."])},
  "levels.convert_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Convert ", _interpolate(_named("levelName")), " OKRs to"])},
  "levels.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
  "levels.and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "levels.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])},
  "levels.add_workspace": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Add workspace"]), _normalize(["Add workspaces"])])},
  "levels.cant_remove_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't remove workspace. An objective is using this level in the workspace. Change the objective's level first, then try again."])},
  "levels.last_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The last OKR level cannot be deleted."])},
  "levels.unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown error"])},
  "levels.add_workspaces": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Add workspace to ", _interpolate(_named("levelName"))]), _normalize(["Add workspaces to ", _interpolate(_named("levelName"))])])},
  "debug.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debug page"])},
  "debug.migrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrations"])},
  "debug.migrate_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrate tasks"])},
  "debug.migrate_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-issues migration"])},
  "debug.migrate_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrate attributes"])},
  "debug.migrate_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task attributes migration"])},
  "debug.migrate_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrate intervals"])},
  "debug.migrate_intervals_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervals migration"])},
  "debug.migrate_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrate levels"])},
  "debug.migrate_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrate labels"])},
  "debug.migrate_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrate email"])},
  "debug.update_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update users info"])},
  "debug.migrate_levels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels migration"])},
  "debug.migrate_labels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels migration"])},
  "debug.migrate_email_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email migration"])},
  "debug.update_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update users information"])},
  "debug.confirm_migration_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to start migration?"])},
  "debug.confirm_migration_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The migration cannot be reverted automatically, don’t start it unless you’ve been asked to do it by the support team."])},
  "debug.start_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start migration"])},
  "debug.restore_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore levels"])},
  "debug.restore_levels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore levels for workspaces"])},
  "debug.clean_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean up"])},
  "debug.clean_up_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean up corrupted links to Jira-issues"])},
  "debug.grade_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade calculation"])},
  "debug.calculate_grades_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch grades re-calculation"])},
  "debug.calculate_grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate grades"])},
  "debug.debug_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debug information"])},
  "debug.rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
  "debug.download_info_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download debug information"])},
  "debug.download_logs_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download logs"])},
  "debug.download_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "debug.upload_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "debug.i_understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand"])},
  "debug.grades_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grades updated"])},
  "debug.restore_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore attributes"])},
  "debug.restore_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore element’s attributes"])},
  "debug.remove_duplicated_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove duplicated intervals"])},
  "debug.remove_users_without_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove users without groups"])},
  "debug.remove_okr_board_global_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove OKR board global permission"])},
  "debug.restore_interval_dates_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore interval  dates for elements"])},
  "debug.restore_users_and_groups_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore users and groups for elements"])},
  "debug.restore_jira_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore Jira details"])},
  "debug.restore_task_chain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore task chain"])},
  "debug.claim_admin-rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim admin rights"])},
  "debug.instance_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instance migration"])},
  "debug.export_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to .zip"])},
  "debug.import_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import from .zip"])},
  "debug.import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import error"])},
  "debug.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "debug.migration_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["- Please migrate your Jira instance first (with all Jira-issues, users, groups etc.), so OKRs don't lose connection with Jira-issues, assignees, etc..", _interpolate(_named("break")), " - Plugin Organization settings will be overwritten.", _interpolate(_named("break")), " - All existing workspaces will be not deleted or overwritten."])},
  "debug.import_file_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The archive is too big, please write us <a href=\"mailto:support", "@", "oboard.io\">support", "@", "oboard.io</a> to increase the limit."])},
  "debug.delete_deactivated_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete deactivated users"])},
  "comments.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type ", "@", " to mention people"])},
  "comments.placeholder.leave_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment…"])},
  "comments.edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited"])},
  "comments.see_last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See last comment"])},
  "comments.add_a_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment"])},
  "comments.open_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open comments"])},
  "comments.more_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " more reply"]), _normalize([_interpolate(_named("count")), " more replies"])])},
  "banner.trial_subscribe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your trial is about to expire. Please subscribe by ", _interpolate(_named("licenseDaysLeft")), " days to continue using OKR board for Jira."])},
  "banner.license_renew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your license is about to expire. Please renew by ", _interpolate(_named("licenseDaysLeft")), " days to continue using OKR board for Jira."])},
  "banner.license_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The license has expired. Please renew to keep using full plugin functionality."])},
  "banner.join_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join now!"])},
  "banner.okr_accelerator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Accelerator for leaders"])},
  "description.normal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal text"])},
  "description.heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 1"])},
  "description.heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 2"])},
  "description.heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 3"])},
  "description.heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 4"])},
  "description.heading_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 5"])},
  "description.heading_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading 6"])},
  "description.underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underline"])},
  "description.position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position:"])},
  "description.strikethrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikethrough"])},
  "description.subscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscript"])},
  "description.superscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superscript"])},
  "description.clear_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear formatting"])},
  "description.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a description…"])},
  "description.type_mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type ", "@", " to mention and notify someone."])},
  "table.no_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items"])},
  "settings.synchronization.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Synchronization lets you keep in sync the due dates in OKR Roadmap with corresponding Jira-issue fields. Settings defined here apply to all issues which were added to OKRs. ", _interpolate(_named("break")), "When enabled, task synchronization is bi-directional and instant. For example, if you move an issue using the OKR Roadmap, your issue’s \"Start Date\" and \"End Date\" fields will be updated. Conversely, if you edit the \"Start Date\" or \"End Date\" fields via the Jira-issue view, the issue changes will be updated and visible in the OKR Roadmap."])},
  "table.no_nested_issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No nested issues"])},
  "settings.synchronization.general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General settings"])},
  "settings.billing.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
  "settings.synchronization.custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom settings"])},
  "settings.synchronization.save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save sync settings"])},
  "settings.synchronization.select_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select project"])},
  "settings.synchronization.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "settings.synchronization.jira_project_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira project name"])},
  "settings.synchronization.no_custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No custom settings yet"])},
  "settings.synchronization.add_custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add custom settings for projects"])},
  "settings.synchronization.jira_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira projects"])},
  "settings.synchronization.select.start_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date field"])},
  "settings.synchronization.select.start_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select start date filed"])},
  "settings.synchronization.select.end_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date field"])},
  "settings.synchronization.select.end_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select end date filed"])},
  "settings.synchronization.add_custom_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add custom setting"])},
  "settings.synchronization.select_synchronisation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select synchronisation type"])},
  "settings.synchronization.oboard_replace_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oboard replaces Jira"])},
  "settings.synchronization.jira_replace_oboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira replaces Oboard"])},
  "settings.synchronization.keep_value_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep Jira’s original value if the OKR’s value is null."])},
  "settings.synchronization.keep_value_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep OKR’s original value if the Jira value is null."])},
  "settings.synchronization.delete_custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete custom settings"])},
  "settings.synchronization.sync_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync in progress…"])},
  "settings.synchronization.synchronization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to delete custom synchronization settings for ", _interpolate(_named("projectName")), "?"])},
  "settings.synchronization.jira_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Jira values will be replaced (overwritten) by OKR values 1 to 1."])},
  "settings.synchronization.oboard_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All OKR values will be replaced (overwritten) by Jira values 1 to 1."])},
  "settings.synchronization.new_custom_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New custom setting"])},
  "settings.synchronization.not_synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not synchronized"])},
  "settings.company.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company setting"])},
  "settings.company.dashboard_visibility_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard visibility"])},
  "settings.company.dashboard_switch_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show ", _interpolate(_named("dashboardName")), " to users"])},
  "settings.company.dashboard_visibility_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unchecked dashboard sections will be hidden for everyone, but administrators."])},
  "settings.company.homepage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR homepage setup"])},
  "settings.company.homepage_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block exploring other people progress"])},
  "settings.company.homepage_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The option to check progress will be disabled for a regular user. ", _interpolate(_named("break")), "Only WS Administrator and Team leads will be able to check progress."])},
  "settings.company.alignment_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR hierarchy configuration"])},
  "settings.company.alignment_settings_switch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow ", _interpolate(_named("objective")), " and ", _interpolate(_named("keyResult")), " to be linked to key result"])},
  "settings.company.default_states_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default states"])},
  "settings.company.default_states_okr_switch_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enable contribute for ", _interpolate(_named("entity"))])},
  "settings.company.default_states_okr_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nested objectives are contributing to their parents."])},
  "settings.company.default_states_tasks_switch_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enable contribute for ", _interpolate(_named("entity"))])},
  "settings.company.default_states_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nested Jira-issues are contributing to their parents."])},
  "settings.company.default_users_role_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default user role assigned when added to a workspace"])},
  "settings.company.default_user_role_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a user is added manually, the role can be selected there as well as it can be changed at any time if required."])},
  "settings.company.okr_privacy_settings_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Privacy Setting"])},
  "settings.company.okr_privacy_settings_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let workspace admins view private OKRs"])},
  "settings.company.okr_privacy_settings_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace admins can manage private elements within available workspaces."])},
  "settings.company.okr_grading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR grading approach"])},
  "settings.company.ws_admins_manage_permission": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Allow workspace administrators to manage users and groups in ", _interpolate(_list(0)), "."])},
  "settings.company.ws_admins_allowed_entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all the workspaces"])},
  "settings.company.adjust_thresholds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust thresholds representing statuses by pulling the dots on the line."])},
  "settings.private_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private workspaces"])},
  "settings.public_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public workspaces"])},
  "settings.roles_in_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles in the workspaces"])},
  "settings.learn_more_email_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about email notifications"])},
  "settings.get_email_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get email updates for OKR activity when"])},
  "settings.when_someone_manually_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When someone manually changes status or leaves a comment in the OKRs where:"])},
  "labels.switch_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow label creation from OKR forms"])},
  "labels.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label name"])},
  "labels.no_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No labels yet"])},
  "labels.no_labels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first label to get started"])},
  "labels.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No labels found"])},
  "labels.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove label"])},
  "labels.delete_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure to remove label ", _interpolate(_named("labelName")), " from all objectives and key results?"])},
  "labels.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create label"])},
  "labels.toast.create_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label created"])},
  "labels.new_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Label"])},
  "jira.dashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up dashboard for OKR"])},
  "jira.dashboard.okrs_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKRs displayed (max 100)"])},
  "jira.dashboard.okrs_displayed_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers 1 to 100 are allowed"])},
  "jira.dashboard.hierarchy_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierarchy view"])},
  "jira.dashboard.okr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR type"])},
  "jira.dashboard.okr_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Types"])},
  "jira.dashboard.okr_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR explorer"])},
  "jira.dashboard.access.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don’t have access to the workspace."])},
  "jira.dashboard.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alignment"])},
  "jira.sidebar.unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Jira-issue will be unlinked from the objective or key result. Confirm, please."])},
  "jira.auto_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto grade"])},
  "jira.manual_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual grade"])},
  "jira.auto_grade.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto grade is based on the Jira-issue resolution status or the progress of its subtasks"])},
  "notifications.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "notifications.oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops…"])},
  "notifications.lost_group_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seems You lost access to this group. Please contact your administrator"])},
  "notifications.excel_export_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report is requested and will be downloaded soon."])},
  "notifications.excel_export_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report is being prepared and will be sent to your email once ready."])},
  "notifications.link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied."])},
  "notifications.plugin_version_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plugin version copied."])},
  "notifications.administrator_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator added."])},
  "notifications.groups_removed_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group removed from workspace."])},
  "notifications.user_deleted": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User deleted."]), _normalize(["Users deleted."])])},
  "notifications.role_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role changed."])},
  "notifications.comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment deleted"])},
  "notifications.comment_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
  "notifications.role_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role updated successfully."])},
  "notifications.channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
  "view_select.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alignment view"])},
  "view_select.explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List explorer"])},
  "view_select.mindmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR map"])},
  "stand_with_ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stand With Ukraine"])},
  "view_select.roadmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmap"])},
  "view_select.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "period_mode.manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
  "period_mode.auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto bottom-up"])},
  "period_mode.auto.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start and End dates scheduled ", _interpolate(_named("break")), " based on it's nested elements"])},
  "period_mode.manual.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start and End dates ", _interpolate(_named("break")), " scheduled manually"])},
  "validation.input_number.allowed_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed numbers, dot, comma"])},
  "validation.input_number.allowed_max_characters_before_dot": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allowed ", _interpolate(_named("integerMaxLength")), " characters before dot"])},
  "validation.input_number.allowed_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allowed ", _interpolate(_named("integerMaxLength")), " characters"])},
  "validation.input_number.allowed_one_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed one dot"])},
  "validation.input_number.allowed_one_minus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed one minus"])},
  "validation.input_number.integer_begins_with_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integer begins with zero"])},
  "expired_license.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your OKR Board for ", _interpolate(_named("platform")), " license ", _interpolate(_named("break")), "is no longer valid"])},
  "expired_license.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keep using OKR dashboards, roadmaps and other powerful tools ", _interpolate(_named("break")), "from OKR Board for ", _interpolate(_named("platform")), " by subscribing to the paid plan."])},
  "expired_license.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase license"])},
  "expired_license.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See detailed pricing"])},
  "expired_license.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are not the JIRA Admin, request them to enable the access"])},
  "confluence.settings.api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API token"])},
  "confluence.permission_less.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have a permission to see this page"])},
  "confluence.permission_less.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you think this is incorrect, please contact your JIRA or Oboard administrators."])},
  "confluence.token.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The API token generated by your Oboard app on your JIRA instance. Please contact your Oboard Jira admin to get API token."])},
  "confluence.error_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong."])},
  "confluence.error_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please reload the page."])},
  "confluence.okr_board_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Board connection is currently"])},
  "confluence.add_okrs_to_confluence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add OKRs to Confluence"])},
  "missing_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, sorry we can’t find that page"])},
  "missing_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Either something went wrong or the page doesn’t exist anymore."])},
  "missing_page.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page"])},
  "text_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard"])},
  "common.link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied to clipboard"])},
  "units.day": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Day"]), _normalize(["Days"])])},
  "units.week": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Week"]), _normalize(["Weeks"])])},
  "units.hour": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hour"]), _normalize(["Hours"])])},
  "units.month": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Month"]), _normalize(["Months"])])},
  "units.px": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["px"])},
  "figma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figma"])},
  "miro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miro"])},
  "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
  "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
  "in_app_notifications.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "in_app_notifications.category.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "in_app_notifications.category.older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older"])},
  "in_app_notifications.no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no notifications"])},
  "in_app_notifications.notification_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " mentioned you on the ", _interpolate(_named("element"))])},
  "in_app_notifications.button.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unread notifications"])},
  "in_app_notifications.notification.status.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " changed status"])},
  "in_app_notifications.notification.commented.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " commented on an ", _interpolate(_named("element"))])},
  "in_app_notifications.notification.replied.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " replied to you on an ", _interpolate(_named("element"))])},
  "in_app_notifications.notification.updates.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["+ ", _interpolate(_named("qty")), " updates from ", _interpolate(_named("userName")), " and others"])},
  "in_app_notifications.notification.show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
  "okr_elements.kr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KR"])},
  "okr_elements.objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objective"])},
  "okr_elements.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives"])},
  "lock_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You don’t have rights to edit this ", _interpolate(_named("element"))])},
  "lock_message.grade_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This element is ", _interpolate(_named("status")), ". Grades are not updated for ", _interpolate(_named("status")), " elements."])},
  "lock_message.grade_closed.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To edit or update grades, please reopen the element."])},
  "lock_message.entity_parent_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Parent ", _interpolate(_named("parentOkr")), " is ", _interpolate(_named("statusName")), " and its grade will not be updated"])},
  "lock_message.kr_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The grade cannot be edited as KR is ", _interpolate(_named("statusName"))])},
  "element.set_new_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Change ", _interpolate(_named("okr")), " status to ", _interpolate(_named("status"))])},
  "element.type.key_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key result"])},
  "element.type.jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-issue"])},
  "element.close_nested_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change status for all the nested key results and objectives"])},
  "element.no_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No elements match this search"])},
  "badge.already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already added"])},
  "badge.added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added"])},
  "badge.already_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already linked"])},
  "badge.soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
  "notification.contact_ws_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact your workspace administrator."])},
  "okr_element.rename.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new name"])},
  "global_groups.add_sub_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a subgroup"])},
  "global_groups.manage_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage users"])},
  "global_groups.manage_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage group"])},
  "global_groups.merge_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge groups"])},
  "global_groups.select_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select parent group"])},
  "global_groups.select_default_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select default user role"])},
  "global_groups.select_default_role.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This role will be auto-assigned when a user joins a workspace where group is added"])},
  "global_groups.parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent group"])},
  "global_groups.default_user_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default user role"])},
  "global_groups.add_selected_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Add ", _interpolate(_named("count")), " selected group"]), _normalize(["Add ", _interpolate(_named("count")), " selected groups"])])},
  "global_groups.merge_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least two groups to merge"])},
  "global_groups.audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit log"])},
  "global_groups.unavailable_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don’t have rights to manage this group"])},
  "global_groups.available_only_in_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available in Jira plugin only"])},
  "global_groups.unavailable_for_add_to_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to be a workspace admin in all the workspaces the group assigned to. Please ask your plugin administrator to grant the rights."])},
  "global_groups.users_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding or removing users might affect in workspaces"])},
  "global_groups.merge_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merging groups will affect in workspaces"])},
  "global_groups.delete_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("groupName")), "? Group will be deleted from all the objectives and all the workspaces!"])},
  "global_groups.keep_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep users in workspaces after deleting the group."])},
  "global_groups.delete_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete users from workspaces (unless users are in any other groups in the WS)."])},
  "global_groups.add_groups_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add groups to the workspace"])},
  "global_groups.add_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add groups"])},
  "add_users_without_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User wil be added without any group"]), _normalize(["Users wil be added without any group"])])},
  "global_groups.remove_from_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Remove ", _interpolate(_named("userName")), " from group"]), _normalize(["Remove ", _interpolate(_named("userName")), " from groups"])])},
  "global_groups.will_be_removed_from_ws": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User might be also removed from the workspaces"]), _normalize(["User might be also removed from the workspaces"])])},
  "global_groups.sub_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subgroups"])},
  "global_groups.without_parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without parent group"])},
  "global_groups.no_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users in this group yet"])},
  "global_groups.no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users were found to match your search"])},
  "global_groups.no_users.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add users to this group to use them across workspaces"])},
  "global_groups.remove_from_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove group from workspace"])},
  "global_groups.remove_from_workspace.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to remove ", _interpolate(_named("groupName")), " from workspace?"])},
  "audit_log.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "audit_log.workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace"])},
  "audit_log.element_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Name"])},
  "action.add_to_group.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["User is added ", _interpolate(_named("break")), "to all the workspaces ", _interpolate(_named("break")), "linked to the group"])},
  "user_details.add_user.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add user to a workspace ", _interpolate(_named("break")), "with a specific group"])},
  "custom_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom fields"])},
  "custom_fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
  "custom_fields.for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For"])},
  "custom_fields.content_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content area"])},
  "custom_fields.right_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right panel"])},
  "custom_fields.no_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No custom fields"])},
  "custom_fields.fields.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first custom field"])},
  "custom_fields.create_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create custom field"])},
  "custom_fields.add_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add custom field"])},
  "custom_fields.disable_create_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask your administrator add new custom field."])},
  "custom_fields.disable_create_button_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Currently, it is only possible to create ", _interpolate(_named("limit")), " custom fields."])},
  "custom_fields.single_line_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single line text"])},
  "custom_fields.long_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long text"])},
  "custom_fields.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "custom_fields.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "custom_fields.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "custom_fields.link.name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name to display"])},
  "custom_fields.link.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have reached the maximum number (", _interpolate(_named("limit")), ") of links for the OKR."])},
  "custom_fields.assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])},
  "custom_fields.single_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single-select"])},
  "custom_fields.multi_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-select"])},
  "custom_fields.money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money"])},
  "custom_fields.options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "custom_fields.options.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add option"])},
  "custom_fields.options.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter option name"])},
  "custom_fields.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "custom_fields.currency.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter currency"])},
  "custom_fields.field_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field type"])},
  "custom_fields.field_type.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select field type"])},
  "custom_fields.field_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field name"])},
  "custom_fields.field_name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter field name"])},
  "custom_fields.add_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add for"])},
  "custom_fields.help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help text"])},
  "custom_fields.help_text.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter help text"])},
  "custom_fields.add_field_to_placement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add field to ", _interpolate(_named("placement"))])},
  "custom_fields.warning.filtering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This filed can't be filtered or sorted"])},
  "custom_fields.warning.not_editable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " cannot be edited in future"])},
  "custom_fields.warning.options.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can configure options only when creating a new select field."])},
  "custom_fields.warning.options.create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can configure options only when creating a new select. ", _interpolate(_named("break")), "Options cannot be edited in future."])},
  "custom_fields.notification.created_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Field ", _interpolate(_named("fieldName")), " ", _interpolate(_named("action")), " successfully"])},
  "custom_fields.delete_field_confirm_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete custom field ", _interpolate(_named("fieldName")), "? All information from this field will be deleted!"])},
  "custom_fields.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete custom field"])},
  "custom_fields.field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Set ", _interpolate(_named("fieldName")), "…"])},
  "custom_fields.text_field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add ", _interpolate(_named("fieldName")), "…"])},
  "edit_later.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can edit them later"])},
  "onboarding.setup_name.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Oboard!"])},
  "onboarding.setup_name.input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your name"])},
  "onboarding.welcome.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome, ", _interpolate(_named("userName"))])},
  "onboarding.welcome.rerun_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were assigned the Admin role!"])},
  "onboarding.welcome.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are excited to have you in our app. Let’s start our journey together."])},
  "onboarding.welcome.rerun_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is an executive role that allows you to manage the OKR process. You can now set goals, configure cycles and other details, manage user permissions and roles."])},
  "onboarding.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to the Oboard newsletter with essential product updates, promos, and announcements for upcoming events and webinars. About 1 per month, no spam."])},
  "onboarding.cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow app use strictly necessary cookies for technical maintenance of the app and services. These cookies help us keep your data nad the Services safe and secure."])},
  "onboarding.demo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet one of our experts"])},
  "onboarding.demo.product_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product demo"])},
  "onboarding.demo.product_demo.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book your free demo session and discover the effective implementation of OKRs across various scales."])},
  "onboarding.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a review of your OKRs and power up your OKR learning with the best coaches."])},
  "onboarding.tutorial.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch video guide"])},
  "onboarding.tutorial.admin_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin setup guide"])},
  "onboarding.tutorial.user_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User guide"])},
  "onboarding.admins.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more admins"])},
  "onboarding.invite_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite the OKR Board admin"])},
  "onboarding.existed_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Board admins"])},
  "onboarding.step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step"])},
  "setup_okr_levels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up your OKR levels"])},
  "setup_workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up your workspace"])},
  "setup_workspace.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can rename your workspace later and add more as you go."])},
  "setup_intervals.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up OKR intervals"])},
  "setup_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up groups"])},
  "setup_groups.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This is a default org structure. ", _interpolate(_named("break")), "You can customise it later in the settings section."])},
  "organization.create_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up your organization"])},
  "organization.create_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The organization name will only be visible to your team. ", _interpolate(_named("break")), "You can change it in the future."])},
  "organization.invite_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite users"])},
  "organization.invite_users.email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter one or more email addresses separated by coma or space"])},
  "organization.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name"])},
  "organization.name.updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name updated successfully"])},
  "limitations.create_button.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For your subscription it's only possible to create ", _interpolate(_named("limit")), " ", _interpolate(_named("entity")), "."])},
  "limitations.entity.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have reached the maximum number (", _interpolate(_named("limit")), ") of ", _interpolate(_named("entity")), "."])},
  "subscription.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "subscription.current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current plan"])},
  "subscription.manage_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage plan"])},
  "subscription.manage_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage tier"])},
  "subscription.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
  "subscription.subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "subscription.user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User tier"])},
  "subscription.next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next payment"])},
  "subscription.billing_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing cycle"])},
  "subscription.next_payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next payment date"])},
  "subscription.compare_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare plans"])},
  "subscription.upgrade_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade tier"])},
  "subscription.invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice history"])},
  "subscription.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
  "subscription.receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
  "subscription.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
  "subscription.billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
  "subscription.tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax ID"])},
  "subscription.try_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why not to try annual plan? Save 15%"])},
  "subscription.switch_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to annual"])},
  "subscription.switch_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to monthly"])},
  "subscription.upgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to annual"])},
  "subscription.upgrade_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to monthly"])},
  "subscription.downgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgrade to annual"])},
  "subscription.your_current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current plan"])},
  "subscription.your_current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current tier"])},
  "subscription.downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgrade"])},
  "subscription.leave_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave anyway"])},
  "subscription.apply_discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apply ", _interpolate(_named("percent")), " discount"])},
  "subscription.leaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry that you're leaving"])},
  "subscription.up_sale_promocode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please consider ", _interpolate(_named("discount")), " to stay with us"])},
  "subscription.discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " discount"])},
  "subscription.subscription_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription is canceled"])},
  "subscription.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
  "subscription.tier_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users amount exceeds tier limit. Upgrade your tier or delete users"])},
  "subscription.license_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License inactive"])},
  "subscription.contact_your_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact your manager"])},
  "subscription.switch_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch plan"])},
  "subscription.select_user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select user tier"])},
  "subscription.select_subscription_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a subscription cycle"])},
  "subscription.per_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per user"])},
  "subscription.per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per month"])},
  "subscription.current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current tier"])},
  "subscription.expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
  "subscription.cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel subscription"])},
  "subscription.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
  "subscription.contact_oboard_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Oboard Team"])},
  "subscription.schedule_free_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule a free demo session and see how to properly implement OKRs on different scales."])},
  "subscription.book_meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a meeting"])},
  "subscription.drop_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop a message"])},
  "subscription.your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
  "subscription.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business email"])},
  "subscription.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
  "subscription.company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company size"])},
  "subscription.get_your_price_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get your price quote"])},
  "subscription.is_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start subscription"])},
  "subscription.choose_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose plan"])},
  "subscription.back_to_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to billing"])},
  "subscription.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "subscription.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "subscription.subscription_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription successfully updated"])},
  "subscription.days_remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days remaining"])},
  "subscription.subscription_free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free trial"])},
  "subscription.state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "subscription.total_pay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total: ", _interpolate(_named("value"))])},
  "subscription.applied_balance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Applied balance ", _interpolate(_named("value"))])},
  "subscription.amount_due": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total due today ", _interpolate(_named("value"))])},
  "subscription.final_amount_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Final amount due is determined at checkout"])},
  "subscription.from_to_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " of ", _interpolate(_named("to")), " users"])},
  "subscription.payment_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment incomplete. Check your balance or update payment method."])},
  "subscription.upgrade_your_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade your tier"])},
  "subscription.upgrade_your_tier_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Users amount exceeds tier limit. ", _interpolate(_named("day")), " day left to upgrade your tier or delete users."]), _normalize(["Users amount exceeds tier limit. ", _interpolate(_named("day")), " days left to upgrade your tier or delete users."])])},
  "subscription.save_count_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Save ", _interpolate(_named("count")), "%"])},
  "subscription.annually_save_count_percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annually SAVE 15%"])},
  "subscription.annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annually"])},
  "subscription.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
  "subscription.tier_upper_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tier upper limit can’t be less than current users amount."])},
  "subscription.expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires:"])},
  "subscription.subscription_wasnt_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription wasn't updated"])},
  "subscription.subscription_will_be_downgraded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subscription will be downgraded to ", _interpolate(_named("name")), " ", _interpolate(_named("cycle")), " in the end of cycle."])},
  "subscription.number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of users"])},
  "subscription.bills_on_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bills on ", _interpolate(_named("date")), " for 1 month"])},
  "subscription.web_app_only_delete_from_oboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web app only users will be deleted from Oboard"])},
  "subscription.address_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 1"])},
  "subscription.address_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line 2"])},
  "subscription.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "subscription.postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
  "subscription.number_of_users_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add more users at any time. You will be billed for these additional users automatically in the next subscription period only."])},
  "subscription.select_user_tier_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upgrade the user tier at any time. If you upgrade the user tier, your account will be charged a prorated amount based on the percentage of the billing cycle left at the time the tier was upgraded."])},
  "subscription.up_to_count_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Up to ", _interpolate(_named("count")), " users"])},
  "subscription.user_tier_billed_annually": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["User tier ", _interpolate(_named("label")), ", billed annually"])},
  "subscription.per_user_billed_monthly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " users,  $", _interpolate(_named("price")), " per user, billed monthly"])},
  "subscription.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "subscription.next_bill_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next bill date"])},
  "subscription.billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing date"])},
  "subscription.essential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essential"])},
  "subscription.professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
  "subscription.enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise"])},
  "step_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Step ", _interpolate(_named("count")), "."])},
  "connections.connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected"])},
  "connections.connected_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected with"])},
  "connections.disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected"])},
  "connections.connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting…"])},
  "connections.connection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection in progress"])},
  "connections.disconnection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnection in progress"])},
  "connections.disconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnecting…"])},
  "connections.lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost connection"])},
  "connections.copy_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy token"])},
  "connections.get_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get token"])},
  "connections.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token copied"])},
  "connections.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection status"])},
  "connections.token_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste integration token"])},
  "connections.paste_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste token"])},
  "connections.paste_token_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste token from"])},
  "connections.help.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need help?"])},
  "connections.guide.link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration guide"])},
  "integration_settings.web_app.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Jira Plugin token"])},
  "integration_settings.web_app.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you already have token, go to step 2. Otherwise make sure that you have a Jira account. Open Atlassian Marketplace. Install the OKR board Plugin for Jira. Open Plugin and go to Settings/Integrations/Web App."])},
  "integration_settings.web_app.step2.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste Jira Plugin integration token to the input field. Press connect. Connection takes some time."])},
  "integration_settings.jira.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press button to copy integration token. Token life time is 30 minutes. Token can be used only once."])},
  "integration_settings.jira.step2.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To establish connection you need to have Web App account. Press button to open Web App page.", _interpolate(_named("break")), "Sign in. Select target account. Navigate to Settings/Integrations/Jira Plugin."])},
  "integration_settings.jira.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To disconnect press button to open Web App and navigate to related account."])},
  "integration_settings.connection_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seems like Jira plugin has been uninstalled. Restore Jira plugin or disconnect."])},
  "integration_settings.jira_web_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira & Web app"])},
  "integration_settings.messenger_webhook_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("messengerName")), " webhooks"])},
  "integration_settings.messenger_webhook_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can send direct messages into ", _interpolate(_named("messengerName")), " channels by creating  webhook for each channel."])},
  "integration_settings.no_integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No integrations yet"])},
  "integration_settings.no_integrations_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first integration"])},
  "integration_settings.path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Path"])},
  "integration_settings.channel_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel name"])},
  "integration_settings.url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url"])},
  "integration_settings.unlink_webhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink webhook"])},
  "integration_settings.unlink_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to unlink webhook ", _interpolate(_named("name")), "?"])},
  "integration_settings.add_webhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link webhook"])},
  "integration_settings.create_webhook_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can send direct messages into ", _interpolate(_named("name")), " channels by creating  webhook for each channel."])},
  "integration_settings.guide_name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["How to create ", _interpolate(_named("name")), " channel webhook"])},
  "integration_settings.unlink_webhook_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your webhook has been unlinked"])},
  "integration_settings.link_webhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link webhook"])},
  "integration_settings.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have reached the maximum number (", _interpolate(_named("limit")), ") of webhooks."])},
  "integration_settings.add_webhook_path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a webhook path"])},
  "integration_settings.custom_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom name"])},
  "integration_settings.integration_oboard_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Oboard to other platforms that your teams uses. Learn more about integrations in"])},
  "integration_settings.integration_messengers_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Oboard to selected messengers that your teams uses. Learn more about how to create a webhooks in our"])},
  "integration_settings.integration_api_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Oboard to selected tools"])},
  "integration_settings.messengers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messengers"])},
  "integration_settings.messenger_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger guide"])},
  "integration_settings.developers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])},
  "integration_settings.operate_wo_gui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operate without GUI"])},
  "integration_settings.get_access_ws_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get access without Jira"])},
  "integration_settings.link_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link issues, users, groups"])},
  "integration_settings.limit_integration_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You used all available integrations"])},
  "integration_settings.send_reminders_to_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reminders to channels"])},
  "integration_settings.no_webhooks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No webhooks yet"])},
  "integration_settings.create_first_webhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link the first webhook"])},
  "field.url_incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL incorrect"])},
  "disconnect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect the Web App from the Jira App"])},
  "disconnect_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will result in the following changes"])},
  "disconnect_modal_events_list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all Jira-only users from the Web App"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all Jira group connections across both apps"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Jira-issues from all the Web App views"])},
    
  ],
  "connect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect the Web App with the Jira App"])},
  "connect_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you press connect, all workspaces and related OKRs from both instances will be merged into a single database."])},
  "connect_modal.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Web App will inherit the internal settings from the Jira App, such as statuses, thresholds and company settings."])},
  "marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
  "actions.chain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstAction")), " and ", _interpolate(_named("secondAction"))])},
  "feedback.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help us to build the better product"])},
  "feedback.modal.message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is in the plugin do you find most challenging or could be improved upon?"])},
  "feedback.modal.rate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How would you rate us?"])},
  "feedback.modal.review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'd appreciate your review on the marketplace"])},
  "feedback.modal.review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It helps us to grow faster and deliver better product."])},
  "audit_logs.elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elements (OKRs)"])},
  "audit_logs.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The audit log gives you a history of changes."])},
  "audit_logs.all_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All types"])},
  "audit_logs.types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types"])},
  "bulk_actions.disabled_by_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can not edit elements in statuses ", _interpolate(_named("closedStatus")), " and ", _interpolate(_named("abandonedStatus"))])},
  "bulk_actions.disabled_for_nested_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't select lookup elements from Jira"])},
  "bulk_actions.disabled_by_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have reached the maximum number of selected elements (", _interpolate(_named("count")), ")"])},
  "bulk_actions.disabled_by_permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no permission to edit this element"])},
  "bulk_actions.delete_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no permission to delete some elements"])},
  "bulk_actions.delete_modal.title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Delete element"]), _normalize(["Delete elements"])])},
  "bulk_actions.delete_notification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Element was deleted"]), _normalize([_interpolate(_named("count")), " elements were deleted"])])},
  "bulk_actions.delete_modal.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("entity"))])},
  "entity.element": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["element"]), _normalize([_interpolate(_named("count")), " elements"])])},
  "bulk_update.disabled_fields_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some fields are not available because they cannot be used for all selected elements:"])},
  "bulk_update.keep_as_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep as is"])},
  "bulk_update.replace_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace all"])},
  "bulk_update.add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
  "bulk_actions.set_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set value"])},
  "bulk_actions.set_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set date"])},
  "bulk_actions.enable_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable contribution"])},
  "bulk_actions.disable_contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable contribution"])},
  "bulk_actions.select_custom_field_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("customFieldName"))])},
  "bulk_actions.set_custom_field_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Set ", _interpolate(_named("customFieldName"))])},
  "bulk_update.notification.failed.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk update failed"])},
  "bulk_update.notification.failed.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items were updated."])},
  "bulk_update.notification.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All items were successfully updated."])},
  "bulk_update.notification.partial_success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update wasn't fully successful."])},
  "bulk_update.notification.partial_success.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("success")), " out of ", _interpolate(_named("total")), " items were NOT updated."])},
  "file_upload.drop_image_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop image here"])},
  "file_upload.drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop image here or"])},
  "file_upload.choose_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose image"])},
  "file_upload.allowed_formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, BMP, PNG, GIF or WEBP, smaller than 10 MB"])},
  "common.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
  "wysiwyg.menu_list.format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "wysiwyg.menu_list.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header"])},
  "wysiwyg.menu_list.subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subheader"])},
  "wysiwyg.menu_list.bullet_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bullet List"])},
  "wysiwyg.menu_list.numbered_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbered List"])},
  "wysiwyg.menu_list.task_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task List"])},
  "wysiwyg.menu_list.blockquote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockquote"])},
  "wysiwyg.menu_list.code_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code snippet"])},
  "wysiwyg.menu_list.insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert"])},
  "wysiwyg.menu_list.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])},
  "wysiwyg.menu_list.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "img.failed_to_read_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to read file"])},
  "img.error_loading_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading image."])},
  "img.image_is_large_than_allowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The image is larger than the allowed dimensions (greater than ", _interpolate(_named("maxWidth")), " x ", _interpolate(_named("maxHeight")), " pixels)"])},
  "img.not_valid_img_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not valid image format"])},
  "img.file_is_too_big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File is too big"])},
  "installation_app.installation_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation in progress.."])},
  "installation_app.installation_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation complete"])},
  "installation_app.reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please reload page"])},
  "bin.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bin"])},
  "bin.trash_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bin is empty"])},
  "bin.trash_empty_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you delete items, they will appear here."])},
  "bin.restore_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore elements you need to specify following parameters"])},
  "bin.missing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing date"])},
  "schedules.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-ins"])},
  "schedules.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create schedule"])},
  "schedules.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit schedule"])},
  "schedules.display_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display schedule name"])},
  "schedules.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There aren’t any schedules matching the applied filters"])},
  "schedule.frequency.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in frequency"])},
  "schedule.name.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This name will appear on the reminder notification. \nKeep it fun to motivate your team"])},
  "schedule.groups.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users that belongs to these Groups will be assigned to leave check-in where they as an Owner or a Stakeholder."])},
  "schedule.subgroups.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub groups will inherit schedule"])},
  "schedule.reminder.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send update reminder"])},
  "schedule.reminder.before_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["before due"])},
  "schedule.reminder.range.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("unit")), " range from ", _interpolate(_named("min")), " to ", _interpolate(_named("max"))])},
  "schedule_frequencies": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bi-Weekly"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    
  ],
  "schedule_ordinals": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fifth"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
    
  ],
  "schedule.delete_schedule.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete schedule"])},
  "schedule.delete_schedule.confirm_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete schedule ", _interpolate(_named("scheduleName")), "?"])},
  "schedule.no_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No check-ins schedule"])},
  "schedule.fields.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create the first check-in schedule"])},
  "schedule.filters.frequencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequencies"])},
  "schedule.schedule_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule name"])},
  "schedule.frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency"])},
  "schedule.reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder"])},
  "schedule.participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "schedule.name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in name"])},
  "schedule.reached_schedule_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached maximum number of schedules"])},
  "schedule_day_in_month_ordinals": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fourth"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
    
  ],
  "schedules.start_form.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in starts from"])},
  "schedule.start_from.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The day on which checkins will start"])},
  "schedule.frequency.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in cycle frequency and due date"])},
  "schedule.frequency.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the day when all Check-ins have to be repeated and submitted"])},
  "schedule.by_end_of_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by end of day"])},
  "schedule.frequency_common_combination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Repeat ", _interpolate(_named("frequency")), " on ", _interpolate(_named("term")), " by end of day"])},
  "schedule.frequency_custom_combination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["On ", _interpolate(_named("frequency")), " repeat every ", _interpolate(_named("term1")), " on ", _interpolate(_named("term2")), " by end of day"])},
  "schedule.reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in due reminder"])},
  "schedule.reminders.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the selected frequency, set the day when all Check-ins are to be submitted"])},
  "schedule.due_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due day"])},
  "schedule.day_before_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day before due day"])},
  "schedule.notifications.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "schedule.notifications.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose where you want to receive notifications. You can do multiple choice."])},
  "schedule.notifications.in_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In app (default)"])},
  "schedule.notifications.slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])},
  "schedule.notifications.teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MS Teams"])},
  "schedule.notifications.discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "schedule.next_step": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next step: ", _interpolate(_named("stepName"))])},
  "schedule.participants.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add or remove participants"])},
  "schedule.participants.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the groups and users who should be reminded to submits their check-ins"])},
  "schedule.participants.add_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add filter"])},
  "schedule.participants_filter.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude Selected"])},
  "schedule.participants.empty_segments_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one Group or User"])},
  "schedule.created_notification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" schedule created successfully"])},
  "schedule.updated_notification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" schedule updated successfully"])},
  "schedule.notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "schedule.notifications_webhook.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("messengerName")), " notifications"])},
  "schedule.notifications_webhook.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Send notification to selected ", _interpolate(_named("messengerName")), " channels connected to your Oboard account"])},
  "reminders.time_update_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It’s time to update your progress…"])},
  "cookie_info_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy settings"])},
  "cookie_info_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" This tool helps you to select and deactivate various tags / trackers / analytic tools used on this website."])},
  "cookie_info_modal.essential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essential"])},
  "app_name.jira_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira Plugin"])},
  "app_name.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Board for Jira"])},
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oboard"])}
}