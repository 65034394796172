<template>
  <div class="bi-BinPage">
    <PageContentHeader
      :level="3"
      :max-width="HEADER_SIZES.LG"
      :title="t('bin.title')"
      no-margin
      style="--padding-bottom: 24px; --align-items: flex-start"
    />
    <div class="bi-MainContent">
      <div class="bi-Toolbar">
        <div class="bi-Toolbar_Left">
          <IntervalSelectWithCustomRange
            v-model:interval-custom-date="intervalCustomDate"
            v-model:interval-custom-range="intervalCustomRange"
            v-model:selected-dates="selectedDates"
            v-model:selected-interval-custom-range-mode="selectedIntervalCustomRangeMode"
            v-model:selected-intervals="selectedIntervals"
            v-model:show-interval-custom-range-picker="showIntervalCustomRangePicker"
            :custom-range-options-based-on-filters="customRangeOptionsBasedOnFilters"
            :interval-fixed-items="INTERVALS_FIXED_ITEMS"
            :intervals="[]"
            :is-between-mode-selected="isBetweenModeSelected"
            :is-time-range-selected="isTimeRangeSelected"
            :show-custom-range-summary-btn="false"
            :show-no-options-message="false"
            append-to="parent"
            auto-close
            @open="onOpenRangePicker"
          />
          <OkrFilterSelect
            :bottom-fixed-items="getBottomFixedItemsClearSelection(FILTERS_KEYS.OWNER_IDS)"
            :item-label="NAME"
            :item-value="ACCOUNT_ID"
            :model-value="filtersValues[FILTERS_KEYS.OWNER_IDS]"
            :options="assigneesOptions"
            :search-function="getAssignees"
            data-testid="owners-filter"
            has-only-this-button
            n-selected-label="filter.all_users"
            prepend-icon="user-next"
            @update:options="onAssigneesUpdate"
            @update:model-value="selectListFilterValue(FILTERS_KEYS.OWNER_IDS, $event)"
          >
            <template #option-label="{ option }">
              <OwnerFieldOption v-if="option" :option="option" />
            </template>
            <template #bottom-fixed-items="{ bottomFixedItems }">
              <div v-for="item in bottomFixedItems" :key="item.id">
                <BottomFixedSelectItem
                  v-if="isClearSelectionAction(item.action)"
                  @click="bottomFixedItemsHandle(item.action, FILTERS_KEYS.OWNER_IDS)"
                >
                  {{ t(item.text) }}
                </BottomFixedSelectItem>
              </div>
            </template>
          </OkrFilterSelect>
        </div>
        <div class="bi-Toolbar_Right">
          <AppSearch v-model="filtersValues.searchString" />
        </div>
      </div>
      <AppTable
        :columns="TABLE_COLUMNS"
        :data="elementsData"
        :hover-row="tableHoverRow"
        :loading="listState.isLoading"
        no-border
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        sticky-header
        style="--head-padding-top: 8px"
        type="primary-next"
      >
        <template #header-cell="{ column }">
          <template v-if="column.title">
            {{ t(column.title) }}
          </template>
        </template>
        <template #cell="{ columnKey, item, index }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.CHECKBOX">
            <AppCheckbox
              v-model="listState.selectedItems"
              v-tippy="{
                content: bulkCheckboxDisableState(item).tooltip,
                theme: `${DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES} ${DROP_LIST_THEMES.TEXT_CENTER}`,
                placement: 'top',
                arrow: true,
                offset: [0, 8],
                maxWidth: 296
              }"
              :disabled="bulkCheckboxDisableState(item).disabled"
              :value="item.elementId"
              name="b-bulk-actions-checkbox"
            />
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
            <div class="bi-OkrName">
              <OkrIcon :objective="item" />
              <TitleItems
                :id-as-link="false"
                :objective="item"
                id-size="small"
                style="--id-font-weight: 600"
                with-tooltip
              />
            </div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.USER">
            <div class="bi-AvatarWrapper">
              <AppAvatar
                v-tippy="{
                  placement: 'top',
                  content: item.userName
                }"
                :avatar-url="item.userAvatarUrl"
                no-margins
              />
            </div>
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.DATE">
            {{ getDeleteDate(item.deletedDate) }}
          </template>
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.ACTIONS">
            <div class="bi-Actions">
              <TableDropdownMenu
                :entity-id="item.elementId"
                :items="DROPDOWN_MENU_ACTIONS"
                @close="tableHoverRow = -1"
                @open="tableHoverRow = index"
                @item-click="onMenuActionsClick($event, item)"
              />
            </div>
          </template>
        </template>
        <template #loading>
          <BinTableLoader />
        </template>

        <template #footer>
          <NoSearchResults
            v-if="showNoSearchResults"
            :offset-left="GLOBAL_GROUP_TABLE_OFFSET"
            :offset-right="GLOBAL_GROUP_TABLE_OFFSET"
          >
            {{ t('element.no_elements') }}
          </NoSearchResults>

          <InfiniteScrollLoaderNext
            v-if="!listState.isAllItemsLoaded"
            :loading="listState.isLoading"
            @load-more="onLoadMore"
          />

          <AppSnackbar
            :actions="SNACKBAR_ACTIONS"
            :count="listState.selectedItems.length"
            class="bi-Snackbar"
            @close="clearBulkActionsList"
            @action-click="onSnackbarActionClick"
          />
        </template>
      </AppTable>
    </div>
    <!--    <SettingsPagesEmptyState-->
    <!--      v-if="isNoElements && !isFiltersUsed && !listState.isLoading"-->
    <!--      data-testid="empty-state"-->
    <!--      hero-height="128"-->
    <!--      hero-icon="bin-empty-state"-->
    <!--      hero-width="174"-->
    <!--    >-->
    <!--      <template #title> {{ t('bin.trash_empty') }} </template>-->
    <!--      <template #subtitle> {{ t('bin.trash_empty_description') }} </template>-->
    <!--    </SettingsPagesEmptyState>-->
    <BinRestoreModal
      v-model:show="isShowRestoreModal"
      :elements-ids="elementsToRestore"
      @success="onSuccessRestore"
    />
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import BinApiHandler from '@/api/bin'
import UsersApiHandler from '@/api/users'
import {
  clearSelection,
  isClearSelectionAction,
  SELECTS_WITH_CHECKBOX_ITEMS
} from '@/composables/bottom-fixed-items'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { formatDateForAuditLog } from '@/utils/audit-logs'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { UNSELECTED_DATE } from '@/utils/date'
import { MENU_ITEMS_GROUPS } from '@/utils/dropdown-menu'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { DEFAULT_LIMIT, GLOBAL_GROUP_TABLE_OFFSET } from '@/utils/global-groups'
import { OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS } from '@/utils/objectives'
import {
  DEFAULT_VALUE_FOR_FILTER,
  FILTERS_KEYS,
  SELECT_ALL_VALUE
} from '@/utils/okr-elements/filters'
import { getSelectWithSelectAllApiParameter, handleSelectInputWithSelectAll } from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import TableDropdownMenu from '@/components/global-groups/TableDropdownMenu'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import TitleItems from '@/components/objectives/items/TitleItems'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppSnackbar from '@/components/ui/AppSnackbar/AppSnackbar'
import InfiniteScrollLoaderNext from '@/components/ui/InfiniteScrollLoaderNext'
import {
  CUSTOM_RANGE_ITEM,
  useSelectWithCustomRange
} from '@/components/ui/IntervalSelectWithCustomRange/interval-select-with-custom-range-utils'
import IntervalSelectWithCustomRange from '@/components/ui/IntervalSelectWithCustomRange/IntervalSelectWithCustomRange'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import AppSearch from '@/components/ui/Search/Search'
// import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import BinTableLoader from '@/components/ui/SkeletonLoaders/BinTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import BinRestoreModal from '@/components/workspaces/BinRestoreModal'

defineOptions({
  name: 'BinPage'
})

const { ACCOUNT_ID, NAME } = ASSIGNEE_ENTITY_KEYS

const GO_BACK_DAYS = 7
const initialDate = dayjs().subtract(GO_BACK_DAYS, 'day')

const DEFAULT_SELECTED_DATES = {
  dueDates: [UNSELECTED_DATE, UNSELECTED_DATE],
  startDates: [initialDate, UNSELECTED_DATE]
}
const selectedDates = ref(cloneDeep(DEFAULT_SELECTED_DATES))
const DEFAULT_FILTER_VALUES = {
  [FILTERS_KEYS.OWNER_IDS]: cloneDeep(DEFAULT_VALUE_FOR_FILTER),
  searchString: ''
}
const filtersValues = ref(cloneDeep(DEFAULT_FILTER_VALUES))

const getBottomFixedItemsClearSelection = key => {
  return !filtersValues.value[key].includes(SELECT_ALL_VALUE) ? SELECTS_WITH_CHECKBOX_ITEMS : []
}

const { t } = useI18n()
const assignees = ref([])
const assigneesOptions = computed(() => {
  return [
    {
      accountId: 0,
      name: t('filter.all_users'),
      iconName: 'team-next'
    },
    ...assignees.value
  ]
})

const getAssignees = async (searchString = null) => {
  const api = new UsersApiHandler()
  let result = []
  try {
    result = await api.getUserFilter({
      searchString,
      requiredUserAccountIds: getSelectWithSelectAllApiParameter(
        filtersValues.value[FILTERS_KEYS.OWNER_IDS]
      ),
      workspaceId: null
    })
    return result
  } catch (error) {
    handleError({ error })
  }
}

const onAssigneesUpdate = newValue => {
  // filter added in computed 'All selected' option
  assignees.value = newValue.filter(assignee => assignee[ACCOUNT_ID] !== SELECT_ALL_VALUE)
}

const selectListFilterValue = (filterKey, newValue) => {
  const oldValue = filtersValues.value[filterKey]
  // eslint-disable-next-line prefer-const
  let { valueChanged, result } = handleSelectInputWithSelectAll(newValue, oldValue)
  valueChanged = valueChanged || !isEqual(oldValue, result)

  if (valueChanged) {
    filtersValues.value[filterKey] = result
  }
}

const bottomFixedItemsHandle = (action, key) => {
  if (isClearSelectionAction(action)) {
    clearSelection(filtersValues.value, key)
  }
}

const {
  selectedIntervals,
  intervalCustomDate,
  intervalCustomRange,
  selectedIntervalCustomRangeMode,
  showIntervalCustomRangePicker,
  isBetweenModeSelected,
  isTimeRangeSelected,
  customRangeOptionsBasedOnFilters
} = useSelectWithCustomRange({
  selectedDates
})
const INTERVALS_FIXED_ITEMS = [CUSTOM_RANGE_ITEM]

const onOpenRangePicker = () => {
  showIntervalCustomRangePicker.value = true
}

const elementsData = ref([])
const isNoElements = computed(() => {
  return isEmpty(elementsData.value) && !listState.value.isLoading
})

const TABLE_COLUMNS = [
  {
    key: TABLE_COLUMNS_KEYS.CHECKBOX,
    width: 32
  },
  {
    title: 'objectives.table_header_name',
    key: TABLE_COLUMNS_KEYS.NAME,
    width: 'auto'
  },
  {
    title: 'objectives.table_header_deleted_by',
    key: TABLE_COLUMNS_KEYS.USER,
    width: 204,
    align: 'center'
  },
  {
    title: 'objectives.table_header_deleted_at',
    key: TABLE_COLUMNS_KEYS.DATE,
    width: 248
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTIONS,
    width: 24
  }
]

const tableHoverRow = ref(-1)

const { RESTORE } = OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS
const DROPDOWN_MENU_ACTIONS = [
  {
    name: RESTORE.name,
    title: 'action.restore',
    icon: 'restore',
    group: MENU_ITEMS_GROUPS.ACTIONS
  }
]

const onMenuActionsClick = (name, item) => {
  if (name === RESTORE.name) {
    isShowRestoreModal.value = true
    elementsToRestore.value = [item.elementId]
  }
}

// const isFiltersUsed = computed(
//   () =>
//     !isEqual(DEFAULT_FILTER_VALUES, filtersValues.value) ||
//     !isEqual(DEFAULT_SELECTED_DATES, selectedDates.value)
// )

const showNoSearchResults = computed(() => isNoElements.value && !listState.value.isLoading)

const DEFAULT_OFFSET = 0
const DEFAULT_LIST_STATE = {
  offset: DEFAULT_OFFSET,
  isAllItemsLoaded: false,
  isLoading: false,
  selectedItems: []
}

const listState = ref(cloneDeep(DEFAULT_LIST_STATE))
const onLoadMore = async () => {
  if (!listState.value.isLoading) {
    await fetchElements()
  }
}

const SNACKBAR_ACTIONS = [
  {
    name: ACTIONS_KEYS.RESTORE,
    title: 'action.restore',
    icon: 'restore',
    buttonType: 'link-secondary',
    color: 'var(--grey-2-next)',
    disable: false,
    tooltipContent: null,
    removePadding: true
  }
]

const clearBulkActionsList = async () => {
  await nextTick()

  listState.value.selectedItems = []
}

const onSnackbarActionClick = name => {
  if (name === ACTIONS_KEYS.RESTORE) {
    isShowRestoreModal.value = true
    elementsToRestore.value = cloneDeep(listState.value.selectedItems)
  }
}

const isShowRestoreModal = ref(false)

const normalizeDate = date => {
  return date ? date : UNSELECTED_DATE
}
const fetchElements = async () => {
  if (listState.value.isAllItemsLoaded) {
    return
  }

  listState.value.isLoading = true
  const BinApi = new BinApiHandler()
  try {
    const data = await BinApi.getBinData({
      accountIds: filtersValues.value[FILTERS_KEYS.OWNER_IDS].filter(item => item),
      deletedDateTo: normalizeDate(selectedDates.value.dueDates[1]),
      deletedDateFrom: normalizeDate(selectedDates.value.startDates[0]),
      searchString: filtersValues.value.searchString,
      offset: listState.value.offset,
      limit: DEFAULT_LIMIT
    })
    elementsData.value = [...elementsData.value, ...data]

    if (data.length < DEFAULT_LIMIT) {
      listState.value.isAllItemsLoaded = true
    }

    listState.value.offset += DEFAULT_LIMIT
    listState.value.isLoading = false
  } catch (error) {
    handleError({ error })
  }
}

onMounted(async () => {
  const responses = await Promise.all([fetchElements(), getAssignees()])
  const [, assigneesData] = responses
  assignees.value = assigneesData
})

const fetchInitialElements = () => {
  listState.value = cloneDeep(DEFAULT_LIST_STATE)
  elementsData.value = []
  fetchElements()
}

watch(
  () => [filtersValues.value, selectedDates.value],
  () => {
    fetchInitialElements()
  },
  { deep: true }
)

const elementsToRestore = ref([])
const onSuccessRestore = () => {
  elementsToRestore.value = []
  clearBulkActionsList()

  fetchInitialElements()
}

const MAX_SELECTED_ELEMENTS_FOR_BULK_ACTIONS = 100

const bulkCheckboxDisableState = element => {
  const state = {
    disabled: false,
    tooltip: null
  }

  const isLimitExceeded =
    listState.value.selectedItems.length >= MAX_SELECTED_ELEMENTS_FOR_BULK_ACTIONS

  if (isLimitExceeded) {
    state.disabled = !listState.value.selectedItems.includes(element.elementId)
    state.tooltip = state.disabled
      ? t('bulk_actions.disabled_by_count', MAX_SELECTED_ELEMENTS_FOR_BULK_ACTIONS)
      : null

    return state
  }

  return state
}

const getDeleteDate = date => {
  return date ? formatDateForAuditLog(date) : t('bin.missing_date')
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';
@import '~@/assets/styles/snackbar';

.bi-BinPage {
  padding-bottom: $page-bottom-padding;
  width: 100%;
  max-width: $page-width-lg;
}
.bi-MainContent {
  width: 100%;
  max-width: 100%;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  font-family: $system-ui;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bi-Toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bi-Toolbar_Left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bi-Actions {
  margin-left: auto;
  @extend %app-table-hidden-items;
}

.bi-OkrName {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bi-Snackbar {
  @extend %snackbar-style;
}

.bi-AvatarWrapper {
  display: flex;
  justify-content: center;
}
</style>
