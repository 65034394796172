<script>
import { defineComponent, h } from 'vue'

const TITLE_LEVELS = [1, 2, 3, 4, 5, 6]

export default defineComponent({
  name: 'AppTitle',
  props: {
    level: {
      type: Number,
      validator: v => TITLE_LEVELS.includes(v),
      default: null
    },

    disableMargin: {
      type: Boolean
    },

    disableTopMargin: {
      type: Boolean
    },

    disableBottomMargin: {
      type: Boolean
    },

    color: {
      type: String,
      default: ''
    },

    inline: {
      type: Boolean
    }
  },

  render() {
    const { level, disableMargin, inline, disableTopMargin, disableBottomMargin } = this
    return h(
      this.level ? `h${level}` : 'div',
      {
        class: {
          'at-Title': true,
          [`at-Title-level-${level}`]: level,
          'at-Title-no-margin': disableMargin,
          'at-Title-no-top-margin': disableTopMargin,
          'at-Title-no-bottom-margin': disableBottomMargin,
          'at-Title-inline': inline
        },

        style: {
          color: this.color
        }
      },
      this.$slots.default()
    )
  }
})
</script>

<style lang="scss" scoped>
.at-Title {
  color: $dark-1;
  font-family: $system-ui;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: var(--white-space, nowrap);

  &-level-1 {
    font-weight: fw('medium');
    font-size: $fs-28;
    margin: 24px 0;
  }

  &-level-2 {
    font-size: $fs-24;
    font-weight: fw('medium');
    margin: 24px 0;
  }

  &-level-3 {
    font-size: $fs-20;
    font-weight: fw('medium');
    margin: 20px 0;
  }

  &-level-4 {
    font-size: $fs-14;
    line-height: 20px;
    font-weight: fw('medium');
    margin: 20px 0;
  }

  &-level-5 {
    font-size: $fs-16;
    font-weight: fw('medium');
    margin: 16px 0;
  }

  &-level-6 {
    font-size: $fs-14;
    font-weight: fw('regular');
    line-height: 22px;
    margin: 12px 0;
  }

  &-no-margin {
    margin: 0;
  }

  &-no-top-margin {
    margin-top: 0;
  }

  &-no-bottom-margin {
    margin-bottom: 0;
  }

  &-inline {
    display: inline;
  }
}
</style>
