<script setup>
import { isEmpty } from 'lodash'

import AppDialog from '@/components/AppDialog'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'

defineOptions({
  name: 'CookieInfoModal'
})
defineProps({
  show: {
    type: Boolean
  },

  hideConfirmButton: {
    type: Boolean
  }
})

const emit = defineEmits({
  'on-close': null,
  'on-confirm': null
})

const onClose = () => emit('on-close')
const onConfirm = () => emit('on-confirm')

const COOKIE_ITEM_KEYS = {
  ID: 'id',
  NAME: 'name',
  IS_ESSENTIAL: 'isEssential',
  IS_ENABLED: 'isEnabled',
  DESCRIPTION: 'description',
  LABEL: 'label',
  VALUE: 'value'
}

const { ID, NAME, IS_ESSENTIAL, DESCRIPTION, LABEL, VALUE, IS_ENABLED } = COOKIE_ITEM_KEYS

// wrap it in ref() if you want to change the value in future
const COOKIES_LIST = [
  {
    [ID]: 0,
    [NAME]: 'Amazon Application Load Balancer',
    [IS_ESSENTIAL]: true,
    [IS_ENABLED]: true,
    [DESCRIPTION]: [
      {
        [ID]: 0,
        [LABEL]: 'Name',
        [VALUE]: 'AWSALBCORS'
      },
      {
        [ID]: 1,
        [LABEL]: 'Purpose',
        [VALUE]: 'The functionality is: to provide load balancing functionality.'
      },
      {
        [ID]: 2,
        [LABEL]: 'Duration',
        [VALUE]: '7 Days'
      }
    ]
  }
]
</script>

<template>
  <AppDialog
    :hide-confirm-button="hideConfirmButton"
    :show="show"
    :size="590"
    :title="$t('cookie_info_modal.title')"
    hide-hero
    @on-close="onClose"
    @on-confirm="onConfirm"
  >
    {{ $t('cookie_info_modal.description') }}

    <div class="cim-List">
      <div v-for="item in COOKIES_LIST" :key="item[ID]" class="cim-List_Item">
        <div class="cim-ListItemHeader">
          <AppCheckbox v-model="item[IS_ENABLED]" :disabled="item[IS_ESSENTIAL]" full-width>
            <template #before>
              <span class="cim-List_ItemHeader_Label">
                <span class="cim-HeaderLabel_Name">
                  {{ item[NAME] }}
                </span>

                <span v-if="item[IS_ESSENTIAL]" class="cim-HeaderLabel_Badge">
                  {{ $t('cookie_info_modal.essential') }}
                </span>
              </span>
            </template>
          </AppCheckbox>
        </div>

        <div v-if="!isEmpty(item[DESCRIPTION])" class="cim-ListItemDescription">
          <div
            v-for="descriptionItem in item[DESCRIPTION]"
            :key="descriptionItem[ID]"
            class="cim-ListItemDescription_Item"
          >
            <span class="cim-DescriptionItem_Label oboard-truncated-text">
              {{ descriptionItem[LABEL] }}:
            </span>
            <span class="cim-DescriptionItem_Value oboard-truncated-text">
              {{ descriptionItem[VALUE] }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <template #confirm-btn-text>
      {{ $t('action.accept') }}
    </template>
    <template #cancel-btn-text>
      {{ $t('action.close') }}
    </template>
  </AppDialog>
</template>

<style lang="scss" scoped>
.cim-List {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cim-List_Item {
  border: 1px solid $grey-2-next;
  border-radius: $border-radius-sm-next;
  overflow: hidden;
  margin-top: 40px;
}

.cim-ListItemHeader {
  padding: 15px 19px;
  --line-height: 20px;
}

.cim-HeaderLabel_Name {
  color: $dark-2;
  font-weight: fw('semi-bold');
}

.cim-HeaderLabel_Badge {
  color: $primary-color-next;
  margin-top: 3px;
  display: block;
}

.cim-ListItemDescription {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: $grey-3-next;
  padding: 23px 19px 33px;
  box-shadow: inset 0 1px 0 0 $grey-2-next;
}

.cim-ListItemDescription_Item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cim-DescriptionItem_Label,
.cim-DescriptionItem_Value {
  white-space: normal;
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
}

.cim-DescriptionItem_Label {
  font-weight: fw('semi-bold');
}
</style>
