<template>
  <AppSelect
    v-model="model"
    :offset="[0, 0]"
    :options="options"
    :type="SELECT_TYPES.MODERN"
    item-value="value"
  >
    <template #button-content="{ option }">
      <template v-if="!option">
        {{ $t('global_groups.select_default_role') }}
      </template>
    </template>
  </AppSelect>

  <template v-if="showDescription || $slots.description">
    <slot name="description">
      <AppInfoMessage :type="INFO_MESSAGE_TYPES.NONE" class="drs-Message">
        {{ $t('global_groups.select_default_role.description') }}
      </AppInfoMessage>
    </slot>
  </template>
</template>

<script setup>
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { SELECT_TYPES } from '@/utils/components-configurations/app-select'
import { numberOrNullProp, stringOrNullProp } from '@/utils/prop-validators'

import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'DefaultRoleSelect',
  inheritAttrs: false
})

defineProps({
  options: {
    type: Array,
    default: () => []
  },

  showDescription: {
    type: Boolean
  }
})

const model = defineModel({
  default: null,
  required: true,
  validator: v => numberOrNullProp(v) || stringOrNullProp(v),
  set: v => v
})
</script>

<style lang="scss" scoped>
.drs-Message {
  --text-padding-top: 2px;
}
</style>
